const host = process.env.VUE_APP_API_URL;
const hostTPC = process.env.VUE_APP_TPC_URL;
const hostAPICampeonatos = process.env.VUE_APP_CAMP_API_URL;
module.exports.host = host;
module.exports.hostTPC = hostTPC;
module.exports.hostAPI = host + "aplicacoes/";
module.exports.hostJogos = host + "jogos/";
module.exports.hostApps = host + "apps/";
module.exports.hostTrofeus = host + "trofeus/";
module.exports.hostCampeonatos = host + "campeonatos/";
module.exports.hostCromos = host + "cromos/";
module.exports.hostAPITpcs = host + "tpcs/";
module.exports.hostCampeonatosCertificados = host + "campeonatos-certificados/";
module.exports.hostAPICampeonatos = hostAPICampeonatos;

module.exports.storageHosts = [
    {origin: /hypatiamat.com$/, allow: ['get', 'set', 'del', 'clear']}
] 
