
import axios from "axios";
const h = require("@/config/hosts").hostAPI;
const hostApps = require("@/config/hosts").hostApps
const anoletivoAtual = require("@/config/confs").anoletivo2;

// codProf: string (required), token (required), anoLetivo: string (not required)
export async function getTurmasFromProf(codProf, token, anoLetivo = null){
    let turmas = [];
    if(codProf && token){
        const anoAtual = getAnoTurmas(anoLetivo);
        const response = await axios.get(h + "professores/" + codProf + `/turmas?ano=${anoAtual}&token=${token}`);
        
        let i = 0;
        for(i = 0; i < response.data.length; i++){
            turmas.push(response.data[i].turma);
        }
    }
    
    return turmas;
}

function getAnoTurmas(anoLetivoParam = null){
    const anoLetivo = anoLetivoParam ? anoLetivoParam : anoletivoAtual;
    const anos = anoLetivo.split("/");
    if(anos && anos.length > 0){
        let ano = anos[0];
        try{
            return ano.substring(2);
        }
        catch{}
    }
    return "23";
}

export async function getEstatisticasPorTarefaFromTurma(turma, table, codProf, dataInicio, dataFim, token){
    const response = await axios.get(hostApps + "tarefas/turmas/" + turma + "/alunos?table=" 
                    + table + "&codProf=" + codProf + "&dataInicio=" + dataInicio + "&dataFim=" 
                    + dataFim + "&token=" + token);
    // response type
    // estatisticasGlobaisPorTarefa: any[]
    // estatisticasPorTarefaEAluno: any[]
    if(response && response.data) return response.data;
    else return [];
}

export function parseResponseFromTarefasWithFixedItemsIfNecessary(responseItems, fixedItems, withTotalAlunos = false){
    let result = [];
    console.log(fixedItems);
    if(fixedItems && fixedItems.length > 0){
        (responseItems || []).forEach(item => {
            const fixedItem = fixedItems.find(e => e.frame === item.frame);
            if(fixedItem){
                let parsedItem = null;
                if(withTotalAlunos){
                    parsedItem = {
                        frame: item.frame,
                        totalAlunos: 0, // todo,
                        ncertas: item.ncertas - fixedItem.ncertas,
                        ntotal: item.ntotal - fixedItem.ntotal,
                        acerto: 0
                    };
                }
                else {
                    parsedItem = {
                        frame: item.frame,
                        ncertas: item.ncertas - fixedItem.ncertas,
                        ntotal: item.ntotal - fixedItem.ntotal,
                        acerto: 0
                    }
                }
                if(parsedItem.ntotal > 0){
                    parsedItem.acerto = Math.round((parsedItem.ncertas/parsedItem.ntotal) * 100);
                    result.push(parsedItem)
                }
            }
            else {
                result.push(item);
            }
        })
    }
    else {
        result = responseItems;
    }
    return result;
}