<template>
    <v-app id="inspire">
      <v-main class="grey lighten-3">
        <v-container>
          <v-card class="pa-5">
              <v-container v-if="!isLoadingAll">
                  <v-card-title primary-title class="justify-center green--text">
                      Apreciações por município
                  </v-card-title>

                  <v-card class="pa-5">
                    <!-- <v-combobox
                        id="turmas"
                        v-model="turmaSel"
                        label="Turma"
                        color="green"
                        :items="turmas"
                        @change="onTurmaChange"
                    ></v-combobox> -->
                    <v-row v-if="!isLoading" class="justify-center align-center">
                      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                          <DropdownExport 
                              :isVisibleProp="true" 
                              :isPDFEnabledProp="true" 
                              :isCSVEnabledProp="false"
                              :isEmailEnabledProp="false"
                              @PDFClicked="exportPDF()"
                          >
                          </DropdownExport>
                      </v-col>
                    </v-row> 
                    <v-row v-if="!isLoading" class="justify-center align-center">
                      <v-col cols="12" sm="6" md="3">
                        <v-checkbox color="green" v-model="atualizarApenasAlunosComDificuldade" label="Apenas alunos c/dificuldade"
                            hide-details />
                      </v-col>
                    </v-row>
                    <v-row v-if="!isLoading" class="justify-center align-center">
                        <v-btn class="white--text" color="#009263" @click="refreshValoresHypatiamat()">
                            <v-icon>mdi-refresh</v-icon>
                            Atualizar valores Hypatiamat
                        </v-btn>
                    </v-row>
                    <v-row v-if="!isLoading" class="justify-center align-center">
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field color="#009263" label="Última data de atualização" placeholder="" type="text" v-model="lastUpdateDate" :readonly="true" hide-details/>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-combobox
                          id="anoletivo"
                          label="Ano Letivo"
                          prepend-icon="mdi-counter"
                          v-model="ano"
                          color="#009263"
                          :items="anos"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12">
                        <v-combobox
                          id="comunidade"
                          label="Comunidade"
                          prepend-icon="mdi-google-circles-communities"
                          v-model="comunidade"
                          color="#009263"
                          :items="comunidades"
                          @change="getDataPorMunicipio"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12">
                        <v-combobox
                          id="anosescolaridade"
                          label="Anos de Escolaridade"
                          prepend-icon="mdi-numeric-1-box-outline"
                          v-model="anosEscolaridadeSel"
                          color="#009263"
                          :items="anosEscolaridade"
                          item-text="texto"
                          multiple
                          chips
                          @change="changeAnosEscolaridade" 
                        ></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row v-if="!isLoading" class="justify-center align-center">
                        <v-btn class="white--text" color="#009263" @click="getDataPorMunicipio()">
                            <v-icon>mdi-refresh</v-icon>
                            Atualizar tabela
                        </v-btn>
                    </v-row>
                  </v-card>
                  <v-container v-if="isLoading">
                    <center><v-img :src="require('@/assets/loading.gif')" width="150px" heigth="150px"> </v-img></center>
                  </v-container>
                  <v-container v-else>
                    <AvaliacoesQuadroGeral id="quadro-geral" v-if="!loading" :estatisticasGerais="estatisticasGerais"/>
                    <v-text-field
                      v-model="filtrar"
                      label="Filtrar"
                      prepend-icon="mdi-magnify"
                      color="#009263"
                      single-line
                    ></v-text-field>
                    <v-data-table
                      class="elevation-1"
                      loading-text="A carregar apreciações..."
                      :headers="headers"
                      :items="items"
                      :footer-props="footer_props"
                      :search="filtrar"
                      @click:row="goToAvaliacoesMunicipio"
                    >
                    </v-data-table>
                  </v-container>   
                    <v-dialog v-model="dialogRefrescar" width="60%" >
                        <v-card id="inspire">
                            
                        </v-card>
                    </v-dialog>
              </v-container>
              <v-container v-else>
                <center><v-img :src="require('@/assets/loading.gif')" width="150px" heigth="150px"> </v-img></center>
              </v-container>
          </v-card>
        </v-container>
      </v-main>
    </v-app> 
  </template>
  
  
  
  <script>
  import axios from "axios";
  import Swal from 'sweetalert2';
  import jsPDF from 'jspdf';

  import AvaliacoesQuadroGeral from '@/components/Avaliacoes/AvaliacoesQuadroGeral';
  import DropdownExport from '@/components/Common/DropdownExport';
  const hypatiaImg = require("@/assets/hypatiamat.png")
  const h = require("@/config/hosts").hostAPI;
  const anoletivo = require("@/config/confs").anoletivo
  const anosletivos = require('@/config/confs').anosletivos
  const anosescolaridade = require('@/config/confs').anosescolaridade
  
    export default {
      components:{
        AvaliacoesQuadroGeral,
        DropdownExport
      },
      name: 'AvaliacoesAdmin',
      props: [],
      data(){
        return {
          token: "",
          items: [],
          municipios: [],
          comunidades: [],
          escolas: [],
          headers: [
              {text: "Munícipio", value: 'municipio', class: 'subtitle-1'},
              {text: "Apps NTRC", value: 'appsNTRC', class: 'subtitle-1'},
              {text: "Apps Acerto (%)", value: 'appsAcerto', class: 'subtitle-1'},
              {text: "Jogos concluídos", value: 'jogosConcluidos', class: 'subtitle-1'},
              {text: "#Alunos c/dificuldades", value: 'alunosComDificuldades', class: 'subtitle-1'}
          ],
          footer_props: {
              "items-per-page-text": "Mostrar",
              "items-per-page-options": [30, 50, -1],
              "items-per-page-all-text": "Todos"
          },
          filtrar : "",
          utilizador:{},
          isLoading: false,
          isLoadingAll: false,
          dialogRefrescar: false,
          tipoAvaliacoes: [],
          lastUpdateDate: "",
          anos: anosletivos,
          ano: anoletivo,
          anosEscolaridade: [{ano: "Todos", texto: "Todos"}].concat(anosescolaridade),
          anosEscolaridadeSel: [{ano: "Todos", texto: "Todos"}],
          anosEscolaridadeAnt: [{ano: "Todos", texto: "Todos"}],
          comunidades:[],
          comunidade: "Nenhuma",
          comunidadesId:[],
          atualizarApenasAlunosComDificuldade: false,
          estatisticasGerais: {
            alunosComDificuldades: 0,
            mediaDiagnosticoHypatiamat: '--',
            mediaFinalHypatiamat: '--',
            mediaTesteAnoAnterior: '--',
            mediaTesteAnoAtual: '--',
            incrementoMedia: 0,
            incrementoMediaHypatiamat: 0,
            incrementoMediaTeste: 0,
            appsNTRC: 0,
            appsAcerto: '--',
            jogosConcluidos: 0
          }
        }
      },
      computed: {
        pdfFileName(){
          let result = `Apreciações-Hypatiamat-${this.ano}.pdf`;
          if(this.comunidade){
            result = `Apreciações-${this.comunidade}-${this.ano}.pdf`;
          }
          return result;
        }
      },
      created: async function(){
          this.token = localStorage.getItem("token");
          this.utilizador = JSON.parse(localStorage.getItem("utilizador"));
          await this.getLastUpdateDate();
          await this.parseComunidades();
          this.getDataPorMunicipio();

          // this.getTipoAvaliacoes();
      },
      methods: {
        showModalToRefreshValoresHypatiamat: function(){
            this.dialogRefrescar = true;
        }, 
        refreshValoresHypatiamat: async function(){
          const title = this.atualizarApenasAlunosComDificuldade ? 
            "Tem a certeza que pretende atualizar os valores hypatiamat de todos os registos de alunos com dificuldade?"
            : "Tem a certeza que pretende atualizar os valores hypatiamat de todos os registos?"
          Swal.fire({
                title: title,
                showDenyButton: true,
                confirmButtonColor: '#009263',
                confirmButtonText: `Sim`,
                denyButtonText: `Não`,
              }).then(async (result) => {
                if(result.isConfirmed){
                  this.isLoadingAll = true;
                  axios.put(`${h}avaliacoes/refreshAppsJogos?apenasAlunosComDificuldade=${this.atualizarApenasAlunosComDificuldade ? '1' : '0'}&token=${this.token}`)
                      .then(() => {
                        Swal.fire({
                            icon: 'success',
                            text: "Valores atualizados com sucesso!",
                            confirmButtonColor: '#009263'
                        });
                        this.isLoadingAll = false;
                        this.dialogRefrescar = false;
                        this.getDataPorMunicipio();
                        this.getLastUpdateDate();
                      })
                      .catch(error =>{
                        this.isLoadingAll = false; 
                        Swal.fire({
                            icon: 'error',
                            text: "Não foi possível atualizar os valores.",
                            confirmButtonColor: '#009263'
                            });
                      });
                }
              })
            
        },
        getDataPorMunicipio: async function(){
          this.isLoading = true;
          let comunidadeQuery = '';
          if(this.comunidade){
            const comunidade = this.comunidadesId.find(c => c.nome == this.comunidade);
            if(comunidade && comunidade.codigo){
              comunidadeQuery = `comunidade=${comunidade.codigo}&`;
            }
          }
          let anosEscolaridadeQuery = '';
          const anosParsed = this.parseAnosEscolaridade();
          if(anosParsed && anosParsed.length){
            anosEscolaridadeQuery = `anosEscolaridade=${anosParsed}&`;
          }
          const response = await axios.get(`${h}avaliacoes/municipios?${comunidadeQuery}${anosEscolaridadeQuery}ano=${this.ano.split('/')[1]}&token=${this.token}`);
          if(response){
            const aux = response.data;
            this.items = aux.items;
            this.estatisticasGerais = aux.gerais;
          }
          this.isLoading = false;
        },
        getLastUpdateDate: async function(){
          const response = await axios.get(`${h}avaliacoes/lastUpdateDate?token=${this.token}`);
          this.lastUpdateDate = response.data || "Nunca foi atualizado";
          return;
        },
        parseAnosEscolaridade : function(){
          const result = []
          if(this.anosEscolaridadeSel.find(e => e.ano == "Todos")) return result
          for(var i = 0; i < this.anosEscolaridadeSel.length; i++)
            result.push(this.anosEscolaridadeSel[i].ano)
          return result
        },
        changeAnosEscolaridade: function(item){
          let aux = null;
          if(this.anosEscolaridadeSel.length == this.anosEscolaridade.length - 1) this.anosEscolaridadeSel = [{ano: "Todos", texto: "Todos"}]
          else if(this.anosEscolaridadeSel.length == 0) this.anosEscolaridadeSel = [{ano: "Todos", texto: "Todos"}]
          else if((aux = this.anosEscolaridadeAnt.find(e => e.ano == "Todos") && this.anosEscolaridadeSel.find(e => e.ano == "Todos"))) {
            var index = this.anosEscolaridadeSel.indexOf(aux)
            this.anosEscolaridadeSel.splice(index, 1)
          }
          else if(this.anosEscolaridadeSel.find(e => e.ano == "Todos")) this.anosEscolaridadeSel = [{ano: "Todos", texto: "Todos"}]
          this.anosEscolaridadeAnt = this.anosEscolaridadeSel
          console.log(this.anosEscolaridadeSel);
          // this.getDataPorMunicipio();
          //this.getEstatisticas()
        },
        parseComunidades: async function(){
          const response = await axios.get(h + "comunidades?token=" + this.token)
          this.comunidadesId = response.data
          var aux = ["Nenhuma"]
          for(var i = 0; i < this.comunidadesId.length; i++){
            aux.push(this.comunidadesId[i].nome)
          }
          this.comunidades = aux;
          return;
        },
        getJSPDF: async function(){
          let doc = new jsPDF({});
          let xImage = doc.internal.pageSize.getWidth() / 4;
          let ytotal = 0;
          const startX = 15;
          const startY = 50;
          let iY = 0;
          doc.addImage(hypatiaImg, 'PNG', xImage, 4);
          //doc.text("Jogo:")
          //doc.text("Estatisticas dos alunos sobre o jogo " + this.jogo + "da turma " + this.turmaSel, doc.internal.pageSize.getWidth() / 2, 8, null, null, 'center')
          doc.setFontSize(11);
          let labelsNum = 2;
          if(this.comunidade){
            doc.text("Comunidade: " + this.comunidade,  startX, startY + (iY++ * 6));
            labelsNum++;
          }
          // doc.text("Período: " + this.dataInicioProp + " até " + this.dataFimProp, 15, 56);
          doc.text("Ano letivo: " + this.ano, startX, startY + + (iY++ * 6));
          doc.text("Data: " + (new Date()).toISOString().split("T")[0], startX, startY + (iY * 6));
          let bodyStartY = startY + (iY * labelsNum) + 8;
          const htmlExport1Height = await this.drawHTMLElementForPdfDoc(doc, "quadro-geral", startX, bodyStartY);
          bodyStartY += htmlExport1Height ? htmlExport1Height + 8 : 0;
          if(this.items && this.items.length){
            bodyStartY = this.drawTable("Municípios", doc, startX, bodyStartY);
          }
          return doc;
        }, 
        exportPDF: async function(){
          const doc = await this.getJSPDF();
          doc.save(this.pdfFileName);
        },
        drawHTMLElementForPdfDoc: async function(doc, htmlElementID, x, y){
          let finalHeight = 0;
          const htmlElement = document.getElementById(htmlElementID);
          if(htmlElement){
            const options = {
              scale: 1,
              useCORS: true,
              allowTaint: true
            }
            const canvas = (await this.$html2canvas(htmlElement, options));
            const imgData = canvas.toDataURL("image/png");

            // Create a jsPDF document and calculate dimensions
            const pdfWidth = doc.internal.pageSize.getWidth() - 30;
            const pdfHeight = doc.internal.pageSize.getHeight();

            // Adjust the image dimensions to fit within the PDF
            const imgWidth = canvas.width / options.scale;
            const imgHeight = canvas.height / options.scale;

            // Scale down the image to fit the PDF dimensions
            const widthRatio = pdfWidth / imgWidth;
            const heightRatio = pdfHeight / imgHeight;
            const ratio = Math.min(widthRatio, heightRatio);

            const finalWidth = imgWidth * ratio;
            finalHeight = imgHeight * ratio;

            // Add the image to the PDF at the calculated size and save
            doc.addImage(imgData, 'PNG', x, y, finalWidth, finalHeight);
          }
          return finalHeight;
        },
        drawTable(title, doc, startX, startY){
          doc.setFont(doc.getFont().fontName, "bold");
          doc.setFontSize(13);
          doc.text(title, startX, startY);
          startY += 6;
          const listaRes = []
          for(let i = 0; i<this.items.length; i++){
              const aux = [];
              aux.push(this.items[i].municipio);
              aux.push(this.items[i].appsNTRC);
              aux.push(this.items[i].appsAcerto);
              aux.push(this.items[i].jogosConcluidos);
              aux.push(this.items[i].alunosComDificuldades);
              listaRes.push(aux);
          }
          const headers = ["Municipio", 'Apps NTRC', "Apps Acerto (%)", "Jogos concluídos", "#Alunos c/dificuldades"];
          doc.autoTable({
              head: [headers],
              body: listaRes,
              headStyles: { fillColor: [0, 146, 99] },
              startY: startY
          });

          // Calculate Y position for the footer after the tables
          let footerYPosition = doc.lastAutoTable.finalY + 40; // Adjust padding as needed
          const pageHeight = doc.internal.pageSize.getHeight();

          // Check if footer will fit on the current page; if not, add a new page
          if (footerYPosition > pageHeight) {
            doc.addPage();
            footerYPosition = 40; // Reset footer position at the top of the new page
          }

          // Add the footer once, after the tables
          doc.setFont(doc.getFont().fontName, "normal");
          doc.setFontSize(8);
          let ydecrement = 26;
          let indexYDecrement = 0;
          doc.text("Legenda:", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
          doc.text("Apps NTRC - N.º de tarefas realizadas corretamente nas aplicações de conteúdo", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
          doc.text("Acerto (%) - Percentagem de acerto nas tarefas realizadas corretamente nas aplicações de conteúdo", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
          doc.text("Jogos concluídos - N.º de jogos realizados", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
          doc.text("#Alunos c/dificuldades - Total de alunos com dificuldades", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
          if(footerYPosition + 60 >= pageHeight){
            doc.addPage();
            return 10;
          }
          else {
            return footerYPosition + 10;
          }
        },
        goToAvaliacoesMunicipio(row){
          this.$router.push({name: 'Apreciações Municipio', params: { municipio: row.municipio}, 
                    query:{ ano: this.ano, anosescolaridade: JSON.stringify(this.anosEscolaridadeSel) } })
        }
      },
      
    }
  </script>