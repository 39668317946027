<template>
    <v-app id="inspire">
      <v-main class="grey lighten-3">
        <v-container>
          <v-card class="pa-5">
              <v-container>
                  <v-card-title primary-title class="justify-center green--text">
                      Gestão de Banners
                  </v-card-title>
                  <center><v-btn class="white--text" style="background-color: #009263;" @click="criarBanner()"><v-icon> mdi-account-plus </v-icon> Criar Banner </v-btn></center>
                                           
                  <v-text-field
                      v-model="filtrar"
                      label="Filtrar"
                      prepend-icon="mdi-magnify"
                      color="#009263"
                      single-line
                      ></v-text-field>
                      <v-data-table
                      class="elevation-1"
                      :headers="header_banners"
                      :items="banners"
                      :footer-props="footer_props"
                      :search="filtrar"
                      color="#009263"
                      >
                      <template v-slot:item="row">
                      <tr>
                          <td>{{row.item.codComunidade}}</td>
                          <td>{{row.item.codMunicipio}}</td>
                          <td>{{row.item.escola}}</td>
                          <td>{{row.item.entryDate }}</td>
                          <td>
                            <v-checkbox color="green" v-model="row.item.ativo" style="margin:0px;padding:0px" @change="ativoClicked(row.item)"
                                hide-details />
                          </td>
                          <td>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    icon
                                    v-bind="attrs" 
                                    v-on="on"
                                    @click="pathBannerImage = row.item.path; dialogImage = true"
                                    >
                                    <v-icon color="#009263">mdi-image</v-icon>
                                    </v-btn>
                                </template>
                                <span>Pré-visualização da imagem do banner.</span>
                            </v-tooltip> 
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    icon
                                    v-bind="attrs" 
                                    v-on="on"
                                    @click="preVisualizarPassaporte(row.item.path)"
                                    >
                                    <v-icon color="#009263">mdi-passport</v-icon>
                                    </v-btn>
                                </template>
                                <span>Pré-visualização do passaporte.</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    icon
                                    v-bind="attrs" 
                                    v-on="on"
                                    @click="apagarBanner(row.item.id)"
                                    >
                                    <v-icon color="#009263"> mdi-delete </v-icon>
                                    </v-btn>
                                </template>
                                <span>Apagar banner.</span>
                            </v-tooltip>
                            <!--<v-icon @click="verProfessor(row.item.id)"> mdi-eye </v-icon>-->
                            <!-- <v-icon @click="editarComunidade(row.item.codigo, row.item.nome)" color="#009263"> mdi-pencil </v-icon> -->
                            
                          </td>
                      </tr>
                      </template>
                      </v-data-table>
                      <v-dialog v-model="dialogCriar" width="70%">
                          <v-card class="pa-5">
                              <v-container>
                                  <v-form>
                                    <v-combobox
                                        id="comunidades"
                                        prepend-icon="mdi-google-circles-communities"
                                        v-model="newBanner.codComunidade"
                                        label="Comunidade"
                                        color="#009263"
                                        :items="comunidades"
                                        :disabled="!!newBanner.codMunicipio || !!newBanner.codEscola"
                                      ></v-combobox>
                                      <v-combobox
                                          id="municipios"
                                          prepend-icon="mdi-city"
                                          v-model="newBanner.codMunicipio"
                                          label="Município"
                                          color="#009263"
                                          :items="municipios"
                                          :disabled="!!newBanner.codComunidade || !!newBanner.codEscola"
                                      ></v-combobox>
                                      <v-combobox
                                        id="escolas"
                                        v-model="escolaSel"
                                        prepend-icon="mdi-school"
                                        label="Agrupamento de Escolas"
                                        item-text="nome"
                                        color="green"
                                        :items="escolas"
                                        :disabled="!!newBanner.codMunicipio || !!newBanner.codComunidade"
                                        @change="onEscolaChange()"
                                      ></v-combobox>
                                      <center>
                                        <v-btn
                                        color="#009263"
                                        class="text-none white--text"
                                        rounded
                                        :loading="isSelectingImage"
                                        @click="onButtonUploadClick"
                                        >
                                            <v-icon left>mdi-cloud-upload-outline</v-icon> {{uploadButtonText}} 
                                        </v-btn>
                                        <input
                                            id="upload"
                                            ref="uploader"
                                            class="d-none"
                                            type="file"
                                            multiple
                                            accept="image/*"
                                            @change="onFileChanged"
                                        >
                                        <br>
                                        <br>
                                        <img 
                                            v-if="selectedFile"
                                            height="32"
                                            width="321"
                                            :src="previewImage" 
                                        />
                                        <br>
                                        <br>
                                      </center>
                                  </v-form>
                                  <v-btn class="white--text" :disabled="(!newBanner.codMunicipio || !newBanner.codComunidade || !newBanner.codEscola) && !selectedFile" 
                                  block style="background-color: #009263;" @click="postBanner()">
                                    Criar Banner
                                  </v-btn>
                              </v-container>
                          </v-card>
                      </v-dialog>
                      <v-dialog v-model="dialogImage" width="60%">
                        <v-card class="pa-2">
                            <center>
                                <v-img
                                    height="32"
                                    width="350"
                                    :src="host + pastaBanners + pathBannerImage"
                                ></v-img>
                            </center>
                        </v-card>
                    </v-dialog>
              </v-container>
          </v-card>
        </v-container>
      </v-main>
    </v-app> 
  </template>
  
  
  
  <script>
  import axios from "axios"
  import Swal from 'sweetalert2'
  import EditarComunidade from '@/components/Comunidades/EditarComunidade.vue'
  const hostA = require("@/config/hosts").host
  const h = require("@/config/hosts").hostAPI
  import { Passaport } from '@/config/passport'
  
    export default {
      components:{
        EditarComunidade
      },
      data(){
        return {
          token: "",
          novoCodigo: "",
          novoNome: "",
          municipios:[],
          comunidades: [],
          novosMunicipios:[],
          loading:false,
          banners: [],
          disabledCodigo:false,
          disabledNome:false,
          disabledMunicipios: false,
          dialogCriar: false,
          dialogEditar: false,
          idEditar: -1,
          nomeEditar: "",
          newBanner: {
            codComunidade: null,
            codMunicipio: null,
            codEscola: null,
            path: null,
            contentType: null,
            ativo: true
          },
          isSelectingImage: false,
          selectedFile: null,
          previewImage: null,
          header_banners: [
              {text: "Comunidade", sortable: true, value: 'codComunidade', class: 'subtitle-1'},
              {text: "Município", sortable: true, value: 'codMunicipio', class: 'subtitle-1'},
              {text: "Agrupamento", sortable: true, value: 'escola', class: 'subtitle-1'},
              {text: "Data Criação", sortable: true, value: 'entryDate', class: 'subtitle-1'},
              {text: "Ativo", value: 'municipios', class: 'subtitle-1'},
              {text: "Operações", class: 'subtitle-1'},
          ],
          footer_props: {
              "items-per-page-text": "Mostrar",
              "items-per-page-options": [50, 100, 200, -1],
              "items-per-page-all-text": "Todos"
          },
          filtrar : "",
          pathBannerImage: null,
          dialogImage: false,
          pastaBanners: 'banners_imgs/',
          host: hostA,
          escolas: [],
          escolaSel: null
        }
      },
      created: async function(){
          this.token = localStorage.getItem("token")
          await this.getAllBanners();
          await this.getEscolas();
          await this.getComunidades();
          await this.parseMunicipios()
  
      },
      computed:{
        uploadButtonText() {
            return this.selectedFile ? 'Imagem Carregada' : 'Imagem'
        },
      },
      methods: {
        getComunidades: async function(){
            const response = await axios.get(h + "comunidades?token=" + this.token);
            this.comunidades = []
            for (let i = 0; i < response.data.length; i++){
                this.comunidades.push(response.data[i].codigo)
            }
        },
        parseMunicipios: async function(){
            const response = await axios.get(h + "escolas/localidades?token=" + this.token)
            this.municipios = []
            for(let i = 0; i < response.data.length; i++){
                this.municipios.push(response.data[i].localidade)
            }
        },
        getAllBanners: async function(){
            const response = await axios.get(h + "banners?token=" + this.token)
            this.banners = response.data
        },
        apagarBanner: async function(id){
          Swal.fire({
            title: "De certeza que deseja apagar este banner?",
            showDenyButton: true,
            confirmButtonColor: '#009263',
            confirmButtonText: `Sim`,
            denyButtonText: `Não`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              const res = (await axios.delete(h + "banners/" + id + "?token=" + this.token)).data
              if(res){
                Swal.fire({
                  icon: 'success',
                  title: 'Banner apagado com sucesso',
                  confirmButtonColor: '#009263'
                });
                this.getAllBanners();
              }
              else {
                Swal.fire({
                  icon: 'error',
                  title: res.message,
                  confirmButtonColor: '#009263'
                })
              }
          
                
            }
          })
        },
        criarBanner: async function(){
            this.newBanner = {
                codComunidade: null,
                codMunicipio: null,
                codEscola: null,
                path: null,
                contentType: null,
                ativo: true
            };
            this.escolaSel = null;
            this.selectedFile = null;
            this.dialogCriar = true
            this.doClear();
        },
        onButtonUploadClick(){
            this.previewImage = null;
            this.isSelectingImage = true
            window.addEventListener('focus', () => {
                this.isSelectingImage = false
            }, { once: true })

            this.$refs.uploader.click()
        },
        doClear(clearUploadValue = false) {
            this.previewImage = null;
            if(clearUploadValue){
                document.querySelector('#upload').value = '';
            }
        },
        onFileChanged(e) {
            this.selectedFile = e.target.files[0];
            this.createPreviewImage(this.selectedFile);
        },
        createPreviewImage(file) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.previewImage = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        onEscolaChange: function(){
            console.log(this.escolaSel);
            if(this.escolaSel && this.escolaSel.cod){
                this.newBanner.codEscola = this.escolaSel.cod;
            }
            else {
                this.newBanner.codEscola = null;
            }
        },
        postBanner(){
            let formData = new FormData();
            formData.append("file", this.selectedFile);
            const pasta = this.newBanner.codComunidade || this.newBanner.codMunicipio || this.newBanner.codEscola;

            axios.post(
                `${h}banners/imagem?pasta=${pasta}&token=${this.token}`, 
                formData, 
                {headers: {'Content-Type': 'multipart/form-data'}}
            )
                    .then(response => {  
                        this.selectedFile = null;
                        this.doClear(true);
                        if(response && response.data){
                            this.newBanner.path = response.data.path;
                            this.newBanner.contentType = response.data.contentType;
                        }
                        else {
                            return;
                        }
                        axios.post(`${h}banners?token=${this.token}`, this.newBanner)
                             .then(async () => {
                                await this.getAllBanners();
                                this.dialogCriar = false;
                                Swal.fire({
                                    icon: 'success',
                                    text: 'Banner inserido com sucesso.',
                                    confirmButtonColor: '#009263'
                                });
                             });
                    })
                    .catch(erro => console.log(erro))
      },
      ativoClicked: async function(updatedBanner){
        if(updatedBanner){
            Swal.fire({
                title: `Tem a certeza que pretende ${updatedBanner.ativo? 'ativar' : 'desativar'} o banner?`,
                showDenyButton: true,
                confirmButtonColor: '#009263',
                confirmButtonText: `Sim`,
                denyButtonText: `Não`,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    axios.put(h + `banners/${updatedBanner.id}/ativo?token=${this.token}`, {});
                }
                else {
                    updatedBanner.ativo = !updatedBanner.ativo;
                }
            });
        }
       },
       preVisualizarPassaporte(path){
        const turma = '4Z-25-1';
        const codigoProfessor = 'hprof2';
        const agrupamento = 'hypatia01';
        Passaport.getPassaporteTurma(turma, codigoProfessor, agrupamento, path);
       },
       getEscolas: async function(){
        const response = await axios.get(h + "escolas?token=" + this.token);
        let data = (response.data || []);
        this.escolas = [...data];
       },
      }
    }
  </script>