import axios from "axios";
const h = require("@/config/hosts").hostAPI;

export function downloadFicheiroFromMensagem(idFicheiro, nomeFicheiro, token){
    return new Promise(function(resolve, reject) {
        axios({
            method: "get",
            url: h + "mensagens/ficheiros/" + idFicheiro + "?token=" + token,
            responseType: 'arraybuffer'
        })
            .then(function (response) {
                if(response.data){
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', nomeFicheiro);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    resolve(true);
                }
                else {
                    resolve(false);
                }
                })
            .catch(error => {
                console.log(error);
                resolve(false);
            })
    });
}