<template>
    <v-app id="inspire">
      <v-main class="grey lighten-3">
        <v-container>
          <v-card class="pa-5">
              <v-container>
                  <v-card-title primary-title class="justify-center green--text">
                      Apreciação de alunos
                  </v-card-title>
                  <center v-if="utilizador.type==20">
                    <v-btn v-if="!show" text @click="show=!show"><span>Mostrar Ajuda</span><v-icon color="#009263"> mdi-help-circle </v-icon> </v-btn>
                    <v-btn v-else text @click="show=!show">Esconder Ajuda</v-btn> 
                  </center>
                  
                  <v-slide-y-transition v-if="utilizador.type==20">
                        <v-card v-show="show" class="elevation-6 pa-3" style="border: 2px solid green !important;" color="grey lighten-3">
                          <v-row >
                            <v-col cols="12">
                              <span> 1. Selecione uma das suas turmas (apenas estão disponíveis turmas do ano letivo atual).
                              </span>
                            </v-col>
                            <v-col cols="12">
                              <span> 2. Na tabela, é possível visualizar os alunos pertencentes à turma selecionada.
                              </span>
                            </v-col>
                            <v-col cols="12">
                              <span> 3. Caso pretenda editar as informações de um aluno, clique em <v-icon>mdi-pencil</v-icon> do aluno pretendido. </span> 
                            </v-col>
                          </v-row>
                        </v-card>
                  </v-slide-y-transition>

                  <v-card class="pa-5" >
                    <v-combobox
                        id="turmas"
                        v-model="turmaSel"
                        label="Turma"
                        color="green"
                        :items="turmas"
                        @change="onTurmaChange"
                    ></v-combobox>
                    <!-- <v-row v-if="!isLoading && (alunos && alunos.length) && turmaSel" class="justify-center align-center">
                        <v-btn class="white--text" color="#009263" @click="refreshValoresHypatiamat()">
                            <v-icon>mdi-refresh</v-icon>
                            Atualizar valores Hypatiamat
                        </v-btn>
                    </v-row> -->
                  </v-card>
                  <v-container v-if="isLoading">
                    <center><v-img :src="require('@/assets/loading.gif')" width="150px" heigth="150px"> </v-img></center>
                  </v-container>
                  <v-container v-else>
                    <v-text-field
                        v-model="filtrar"
                        label="Filtrar"
                        prepend-icon="mdi-magnify"
                        color="#009263"
                        single-line
                    ></v-text-field>
                    <v-data-table
                    class="elevation-1"
                    loading-text="A carregar alunos..."
                    :headers="header_alunos"
                    :items="alunos"
                    :footer-props="footer_props"
                    :search="filtrar"
                    >
                    <template v-slot:item="row" >
                    <tr>
                        <td>{{row.item.numero}}</td>
                        <td>{{row.item.nome}}</td>
                        <td>{{row.item.user}}</td>
                        <td v-if="utilizador.type==20 || utilizador.type == 50">
                          <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs" 
                              v-on="on"
                            >
                            <v-icon @click="editarAvaliacaoAluno(row.item, row.item.avaliacao)"> mdi-pencil </v-icon>
                            </v-btn>
                            </template>
                            <span>Poderá editar a apreciação do seu aluno.</span>
                          </v-tooltip>
                        </td>
                        <td v-if="utilizador.type==20 || utilizador.type == 50">
                            <v-checkbox color="green" v-model="row.item.avaliacao.comDificuldade" style="margin:0px;padding:0px" @change="alunoComDificuldade(row.item.avaliacao)"
                              hide-details />
                        </td>
                    </tr>
                    </template>
                    </v-data-table>
                  </v-container>   
                        <v-dialog v-if="utilizador.type==20 || utilizador.type == 50" class="mydialog" v-model="dialogEditar" width="85%" >
                          <v-card id="inspire">
                            <EditarAvaliacao v-if="dialogEditar" @alteracao="avaliacaoAlterada" :alunoProp="alunoEditar" :avaliacaoProp="avaliacaoEditar" :tiposAvaliacaoProp="tiposAvaliacaoEditar"/>
                          </v-card>
                        </v-dialog>
              </v-container>
          </v-card>
        </v-container>
      </v-main>
    </v-app> 
  </template>
  
  
  
  <script>
  import axios from "axios"
  import Swal from 'sweetalert2'
  import { getTurmasFromProf } from '@/common/turmas';
  import EditarAvaliacao from "../../components/Avaliacoes/EditarAvaliacao.vue";

  const h = require("@/config/hosts").hostAPI
  
    export default {
      components:{
          EditarAvaliacao
      },
      props: [],
      data(){
        return {
          token: "",
          alunos: [],
          turmas: [],
          header_alunos: [
              {text: "Número", value: 'numero', class: 'subtitle-1'},
              {text: "Nome", value: 'nome', class: 'subtitle-1'},
              {text: "Username", value: 'user', class: 'subtitle-1'},
              {text: "Apreciação", sortable:false, class: 'subtitle-1'},
              {text: "Tem dificuldade?", sortable: false, class: 'subtitle-1'}
          ],
          footer_props: {
              "items-per-page-text": "Mostrar",
              "items-per-page-options": [30, 50, -1],
              "items-per-page-all-text": "Todos"
          },
          filtrar : "",
          idTurma: "",
          turma: {},
          turmaSel: "",
          utilizador:{},
          dialogEditar: false,
          idEditarAluno:-1,
          show:false,
          codProf: '', 
          isLoading: false,
          alunoEditar: {},
          avaliacaoEditar: {},
          tiposAvaliacao: [],
          tiposAvaliacaoEditar: []
        }
      },
      created: async function(){
          this.token = localStorage.getItem("token");
          this.utilizador = JSON.parse(localStorage.getItem("utilizador"));
          this.codProf = this.$route.params.codprofessor;
          this.turmas = await this.getTurmasFromProf();
          this.setTipoAvaliacoes();

          if(this.utilizador.type != 20 && this.utilizador.type != 50){
            this.header_alunos = [
              {text: "Número", value: 'numero', class: 'subtitle-1'},
              {text: "Nome", value: 'nome', class: 'subtitle-1'},
              {text: "Username", value: 'user', class: 'subtitle-1'}
            ]
          }
          
      },
      methods: {
        editarAvaliacaoAluno : function(aluno, avaliacao){
          if(aluno && avaliacao){
            this.alunoEditar = {
              numero: aluno.numero,
              user: aluno.user,
              nome: aluno.nome
            };
            this.avaliacaoEditar = avaliacao;
            this.dialogEditar = true;
          }
        },
        getAlunosFromTurma: async function(){
          this.isLoading = true;
          const response = await axios.post(h + "avaliacoes/turmas/" + this.turmaSel + "/alunos?codprofessor="+ this.codProf + "&atuais=1&token=" + this.token, {});
          this.alunos = response.data;
          this.isLoading = false;
        },
        getTurmasFromProf: async function(){
            return getTurmasFromProf(this.codProf, this.token);
        },
        onTurmaChange: function(item){
          if(this.turmaSel && this.turmaSel != ""){
              this.getAlunosFromTurma();
              this.setTipoAvaliacoesEditar();
          }
          else this.alunos = [];
        },
        alunoComDificuldade: async function(avaliacaoUpdated){
          if(avaliacaoUpdated){
            axios.put(h + `avaliacoes/${avaliacaoUpdated.id}/dificuldades?token=${this.token}`, {value: avaliacaoUpdated.comDificuldade});
          }
        },
        refreshValoresHypatiamat: async function(){
          if(this.turmaSel){
            this.isLoading = true;
            const response = await axios.put(h + "avaliacoes/turmas/" + this.turmaSel + "/refreshAppsJogos?atuais=1&token=" + this.token, {});
            const updatedAvaliacoes = (response.data || []);
            for(const aluno of this.alunos){
              const avaliacao = aluno.avaliacao;
              if(avaliacao){
                const updatedAvaliacao = updatedAvaliacoes.find(a => a.id === avaliacao.id);
                if(updatedAvaliacao){
                  avaliacao.lastDate = updatedAvaliacao.lastDate;
                  avaliacao.appsNTRCAnoAtual = updatedAvaliacao.appsNTRCAnoAtual;
                  avaliacao.appsAcertoAnoAtual = updatedAvaliacao.appsAcertoAnoAtual;
                  avaliacao.jogosConcluidosAnoAtual = updatedAvaliacao.jogosConcluidosAnoAtual;
                }
              }
            }
            this.isLoading = false;
          }
        },
        avaliacaoAlterada: function(dadosAlterados){
          if(dadosAlterados && dadosAlterados.user && dadosAlterados.avaliacao){
            const alunoToUpdate = this.alunos.find(a => a.user == dadosAlterados.user);
            if(alunoToUpdate){
              alunoToUpdate.avaliacao = dadosAlterados.avaliacao;
            }
            this.dialogEditar = false;
          }
        },
        setTipoAvaliacoesEditar: function(){
          let result = [];
          if(this.tiposAvaliacao && this.tiposAvaliacao.length && this.turmaSel){
            const splitedValues = this.turmaSel.split("-");
            if(splitedValues && splitedValues.length){
              const anoField = splitedValues[0];
              const anoEscolaridade = (anoField || '').charAt(0);
              if(anoEscolaridade){
                result = this.tiposAvaliacao.filter(t => t.ano == anoEscolaridade);
              }
            }
          }
          console.log(result);
          this.tiposAvaliacaoEditar =  result;
        },
        setTipoAvaliacoes: async function(){
          const response = await axios.get(h + "avaliacoes/tipos?token=" + this.token);
          this.tiposAvaliacao = response.data;
        }
    }
    }
  </script>