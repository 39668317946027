<template>
  <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container>
        <v-card class="pa-5">
            <v-container>
                <v-card-title primary-title class="justify-center green--text">
                    Professores
                </v-card-title>
                <center><v-btn class="white--text" style="background-color: #009263;" @click="criarProfessor()"><v-icon> mdi-account-plus </v-icon> Criar Professor </v-btn></center>
                <br>
                <center>
                  <vue-json-to-csv
                      :json-data="professores"
                      :labels="fieldsToExcelExport"
                      csvTitle="emails"
                      :show-labels="false"
                  >
                    <v-btn class="white--text" style="background-color: #009263;"><v-icon> mdi-file-delimited-outline </v-icon> Exportar e-mail's</v-btn>
                  </vue-json-to-csv>
                </center>
                <v-combobox
                  id="comunidade"
                  label="Comunidade"
                  prepend-icon="mdi-google-circles-communities"
                  item-text="nome"
                  v-model="comunidadeSel"
                  color="#009263"
                  :items="comunidades"
                  :disabled="disableComunidadeChange"
                  @change="onComunidadeChange()"
                ></v-combobox>
                <v-combobox 
                  id="municipio"
                  prepend-icon="mdi-city" 
                  color="#009263" 
                  :items="municipios" 
                  item-text="localidade"
                  v-model="municipioSel" 
                  name="Município" 
                  label="Município"
                  :disabled="disableMunicipioChange"
                  @change="onMunicipioChange()"
                ></v-combobox>
                <v-combobox
                  id="escolas"
                  v-model="escolaSel"
                  prepend-icon="mdi-school"
                  label="Agrupamento de Escolas"
                  item-text="nome"
                  color="green"
                  :items="escolas"
                  :disabled="disableEscolaChange"
                  @change="onEscolaChange()"
                ></v-combobox>
                <v-combobox
                  id="anosescolaridade"
                  label="Anos de Escolaridade"
                  prepend-icon="mdi-numeric-1-box-outline"
                  v-model="anosEscolaridadeSel"
                  color="#009263"
                  :items="anosEscolaridadeItems"
                  item-text="texto"
                  multiple
                  chips
                  @change="changeAnosEscolaridade" 
                ></v-combobox>
                <v-row class="justify-center align-center">
                    <v-btn class="white--text" color="#009263" @click="getProfessores()">
                        <v-icon>mdi-refresh</v-icon>
                        Aplicar
                    </v-btn>
                </v-row>
                <v-container v-if="isLoading">
                  <center><v-img :src="require('@/assets/loading.gif')" width="150px" heigth="150px"> </v-img></center>
                </v-container>
                <v-container v-else>
                  <v-text-field
                    v-model="filtrar"
                    label="Filtrar"
                    prepend-icon="mdi-magnify"
                    color="#009263"
                    single-line
                  ></v-text-field>
                  <v-data-table
                  class="elevation-1"
                  :headers="header_professores"
                  :items="professores"
                  :footer-props="footer_props"
                  :search="filtrar"
                  :custom-filter="customSearch"
                  >
                  <template v-slot:item="row">
                  <tr>
                      <td>
                        <v-tooltip top>
                          <template #activator="{ on }">
                            <v-icon v-on="on" color="#009263">mdi-email</v-icon>
                          </template>
                          <span>{{row.item.email}}</span>
                        </v-tooltip>
                      </td>
                      <td>{{row.item.codigo}}</td>
                      <td>{{row.item.nome}}</td>
                      <td>{{row.item.localidade}}</td>
                      <td>{{row.item.agrupamento}}</td>
                      <td>{{row.item.socionum}}</td>
                      <td>{{row.item.projeto}}</td>
                      <td>{{row.item.validade}}</td>
                      <td>
                      <!--<v-icon @click="verProfessor(row.item.id)"> mdi-eye </v-icon>-->
                      <v-icon @click="editarProfessor(row.item.id)" color="#009263"> mdi-pencil </v-icon>
                      <v-icon @click="apagarProfessor(row.item.codigo)" color="#009263"> mdi-delete </v-icon>
                      </td>
                  </tr>
                  </template>
                  </v-data-table>
                </v-container>
                    <v-dialog v-model="dialogEditar" width="80%">
                      <v-card>
                      <EditarProfessor v-if="dialogEditar" :idProp="idEditar" @alteracao="atualizaProfessores()"/>
                      </v-card>
                    </v-dialog>
            </v-container>
        </v-card>
      </v-container>
    </v-main>
  </v-app> 
</template>



<script>
import axios from "axios"
import Swal from 'sweetalert2'
import EditarProfessor from '@/components/Professores/EditarProfessor.vue'
import VueJsonToCsv from "vue-json-to-csv";
const h = require("@/config/hosts").hostAPI
const anosescolaridade = require('@/config/confs').anosescolaridade;
const anoTodos = {ano: "Todos", texto: "Todos"};
const escolaTodos = {id: -1, cod: null, nome: "Todos"};
const comunidadeNenhuma = {id: -1, codigo: null, nome: "Nenhuma"};
const municipioTodos = {localidade: "Todos"};
  export default {
    components:{
         EditarProfessor,
         VueJsonToCsv
    },
    data(){
      return {
        token: "",
        professores: [],
        professoresOriginal:[],
         header_professores: [
            {text: "Email", class:'subtitle-1'},  
            {text: "Username", sortable: true, value: 'codigo', class: 'subtitle-1'},
            {text: "Nome", value: 'nome', class: 'subtitle-1'},
            {text: "Localidade", value: 'localidade', class: 'subtitle-1'},
            {text: "Agrupamento", value: 'agrupamento', class: 'subtitle-1'},
            {text: "Sócio", value: 'socionum', class: 'subtitle-1'},
            {text: "Projeto", value: 'projeto', class: 'subtitle-1'},
            {text: "Validade", value: 'validade', class: 'subtitle-1'},
            {text: "Operações", class: 'subtitle-1'},
        ],
        footer_props: {
            "items-per-page-text": "Mostrar",
            "items-per-page-options": [50, 100, 200, -1],
            "items-per-page-all-text": "Todos"
        },
        filtrar : "",
        confirmacaoSel: "Todos",
        validadeSel: "Todos",
        validades: ["Todos", "Dentro da validade", "Fora da validade"],
        confirmacoes: ["Todos", "Confirmados", "Não Confirmados"],
        today:"",
        confirmadoBool: false,
        validadeBool: false, 
        idEditar: -1,
        dialogEditar: false,
        dialogEditarTurma: false,
        fieldsToExcelExport: {
          email: { title: 'Email' }
        },
        escolas: [escolaTodos],
        escolaSel: escolaTodos,
        comunidades: [comunidadeNenhuma],
        comunidadeSel: comunidadeNenhuma,
        disableEscolaChange: false,
        disableComunidadeChange: false,
        disableMunicipioChange: false,
        anosEscolaridadeSel: [anoTodos],
        anosEscolaridadeAnt: [anoTodos],
        anosEscolaridadeItems: [anoTodos, ...anosescolaridade],
        municipios: [municipioTodos],
        municipioSel: municipioTodos, 
        isLoading: false
      }
    },
    created: async function(){
        this.token = localStorage.getItem("token")
        this.getMunicipios();
        this.getEscolas();
        this.getComunidades();
        await this.getProfessores(true);
        this.professoresOriginal = this.professores
    },
    methods: {
      verProfessor : function(id){
          this.$router.push({name: "Ver Professor", params: { id : id } })
      },
      editarProfessor : function(id){
          this.dialogEditar = true;
          this.idEditar = id
          //this.$router.push({name: "Editar Professor", params: { id : id } })
      },
      apagarProfessor: async function(id){
        Swal.fire({
          title: "De certeza que deseja apagar este professor?",
          showDenyButton: true,
          confirmButtonColor: '#009263',
          confirmButtonText: `Sim`,
          denyButtonText: `Não`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            var a = await axios.delete(h + "professores/" + id + "?token=" + this.token)
              var apagado = a.data
              if(apagado.removed){
                var response = await axios.get(h + "professores?token=" + this.token)
                this.professores = response.data
              }
              else{
                Swal.fire({
                  icon: 'error',
                  text: apagado.message,
                  confirmButtonColor: '#009263'
                })
              }
          }
        })
      },
      criarProfessor: async function(){
        this.$router.push({name:"Criar Professor"})
      },
      atualizaProfessores: async function(){
        this.dialogEditar = false
        var response = await axios.get(h + "professores/" + this.idEditar + "/?token=" + this.token)
        var al = this.professores.find(a => a.id == this.idEditar) 
        var index = this.professores.indexOf(al)
        if(response.data.valido) this.professores.splice(index, 1, response.data)
        else this.professores.splice(index, 1);
        
      },
      customSearch (value, search, item) {
        return Object.values(item).some(v=> v && v.toString().toLowerCase().includes(search.toLowerCase()))
      },
      getEscolas: async function(){
        const response = await axios.get(h + "escolas?token=" + this.token);
        let data = (response.data || []);
        this.escolas = [escolaTodos, ...data];
      },
      getComunidades: async function(){
        const response = await axios.get(h + "comunidades?token=" + this.token);
        let data = (response.data || []);
        this.comunidades = [comunidadeNenhuma, ...data];
      },
      getMunicipios: async function(){
        const response = await axios.get(h + "escolas/localidades/?token=" + this.token);
        let data = (response.data || []);
        this.municipios = [municipioTodos, ...data];
      },
      onEscolaChange: function(){
        if(this.escolaSel && this.escolaSel.cod){
          this.disableComunidadeChange = true;
          this.disableMunicipioChange = true;
        }
        else {
          this.escolaSel = escolaTodos;
          this.disableComunidadeChange = false;
          this.disableMunicipioChange = false;
        }
      },
      onComunidadeChange: function(){
        if(this.comunidadeSel && this.comunidadeSel.codigo){
          this.disableEscolaChange = true;
          this.disableMunicipioChange = true;
        }
        else {
          this.comunidadeSel = comunidadeNenhuma;
          this.disableEscolaChange = false;
          this.disableMunicipioChange = false;
        }
      },
      onMunicipioChange: function(){
        if(this.municipioSel && this.municipioSel.localidade != "Todos"){
          this.disableEscolaChange = true;
          this.disableComunidadeChange = true;
        }
        else {
          this.municipioSel = municipioTodos;
          this.disableEscolaChange = false;
          this.disableComunidadeChange = false;
        }
      },
      changeAnosEscolaridade: function(item){
        let aux;
        if(this.anosEscolaridadeSel.length == this.anosEscolaridadeItems.length - 1) this.anosEscolaridadeSel = [anoTodos];
        else if(this.anosEscolaridadeSel.length == 0) this.anosEscolaridadeSel = [anoTodos];
        else if((aux = this.anosEscolaridadeAnt.find(e => e.ano == "Todos") && this.anosEscolaridadeSel.find(e => e.ano == "Todos"))) {
          let index = this.anosEscolaridadeSel.indexOf(aux);
          this.anosEscolaridadeSel.splice(index, 1);
        }
        else if(this.anosEscolaridadeSel.find(e => e.ano == "Todos")) this.anosEscolaridadeSel = [anoTodos]
        this.anosEscolaridadeAnt = this.anosEscolaridadeSel;
        // this.getProfessores();
      },
      getEscolaSelInQueryString: function(){
        let result = '';
        if(this.escolaSel){
          if(this.escolaSel.cod){
            result = `&escola=${this.escolaSel.cod}`;
          }
        } 
        return result;
      },
      getComunidadeSelInQueryString: function(){
        let result = '';
        if(this.comunidadeSel){
          if(this.comunidadeSel.codigo){
            result = `&comunidade=${this.comunidadeSel.codigo}`;
          }
        }
        return result;
      },
      getMunicipioSelInQueryString: function(){
        let result = '';
        if(this.municipioSel && this.municipioSel.localidade && this.municipioSel.localidade != "Todos"){
          result = `&municipio=${this.municipioSel.localidade}`;
        }
        return result;
      },
      parseAnosEscolaridade : function(){
        let result = [];
        if(this.anosEscolaridadeSel.find(e => e.ano == "Todos")) return result;
        for(var i = 0; i < this.anosEscolaridadeSel.length; i++)
          result.push(this.anosEscolaridadeSel[i].ano);
        return result;
      },
      getAnosEscolaridadeQueryString: function(){
        let result = '';
        const anosParsed = this.parseAnosEscolaridade();
        if(anosParsed.length > 0){
          result = `&anosescolaridade=${anosParsed}`;
        }
        return result;
      },
      getProfessores: async function(onInit = false){
        this.isLoading = true;
        let queryString = `?token=${this.token}`;
        if(!onInit){
          queryString += this.getEscolaSelInQueryString();
          queryString += this.getComunidadeSelInQueryString();
          queryString += this.getMunicipioSelInQueryString();
          queryString += this.getAnosEscolaridadeQueryString();
        }
        const response = await axios.get(`${h}professores${queryString}`);
        this.professores = response.data;
        this.isLoading = false;
      }
    }
  }
</script>