<template>
    <v-card class="pa-5">
        <v-container>
            <v-card-title primary-title class="justify-center green--text">
                Professores supervisionados do professor {{codSupervisor}}
            </v-card-title>         
           <v-layout row class="text-xs-center pa-lg-16" justify-center >
            <v-flex xs5 >
              <v-card class="pa-4">
                    <v-combobox
                      id="escola"
                      label="Agrupamento de Escolas"
                      v-model="escolaSel"
                      item-text="nome"
                      :items="escolas"
                      @change="getProfessoresFromEscola()"
                  ></v-combobox>
                    <v-text-field
                      v-model="filtrar"
                      label="Filtrar"
                      prepend-icon="mdi-magnify"
                      color="#009263"
                      single-line
                      ></v-text-field>
                    <v-data-table
                      class="list-elements elevation-1"
                      v-model="selected"
                      :single-select="false"
                      show-select
                      :headers="header_professores"
                      :items="professoresEscola"
                      :footer-props="footer_props"
                      :search="filtrar"
                      >
                      </v-data-table>
              </v-card>
            </v-flex>
            <v-flex xs1>
              <v-container>
                <center>
                  <v-tooltip v-if="this.selected.length>0" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs" 
                        v-on="on"
                        @click="adicionarSupervisionados"
                      >
                      <v-icon large color="#009263" @click="adicionarSupervisionados"> mdi-arrow-right-box </v-icon>
                      </v-btn>
                    </template>
                    <span>Irá adicionar os professores selecionados à lista de supervisionados.</span>
                  </v-tooltip>
                  </center>
                <br>
                <center>
                  <v-tooltip v-if="this.selected2.length>0" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs" 
                        v-on="on"
                        @click="removeSupervisionados"
                      >
                      <v-icon  large color="#009263" @click="removeSupervisionados"> mdi-arrow-left-box </v-icon>
                      </v-btn>
                    </template>
                    <span>Irá remover os professores selecionados da lista de supervisionados.</span>
                  </v-tooltip>
                </center>
              </v-container>
            </v-flex>
            <v-flex xs5>
              <v-card class="pa-4">
                <v-card-title primary-title class="justify-center">
                  Professores supervisionados
                </v-card-title>
                  <v-text-field
                      v-model="filtrar2"
                      label="Filtrar"
                      prepend-icon="mdi-magnify"
                      color="#009263"
                      single-line
                      ></v-text-field>
                      <v-data-table
                      class="list-elements elevation-1"
                      v-model="selected2"
                      :single-select="false"
                      show-select
                      :headers="header_professores"
                      :items="professoresSupervisionados"
                      :footer-props="footer_props"
                      :search="filtrar2"
                      >
                      </v-data-table>
              </v-card>        
            </v-flex>
    </v-layout>


        
        </v-container>
    </v-card>
</template>



<script>
import axios from "axios"
import Swal from 'sweetalert2'
const h = require("@/config/hosts").hostAPI
const anoLetivoAtual = require("@/config/hosts").anoAtual

  export default {
    props:["codSupervisor", "escola"],
    data(){
      return {
        selected:[],
        selected2:[],
        escolaSel: null,
        professoresSupervisionados: [],
        professoresEscola: [],
        codProfessor: null,
        dialogTransferencia: false,
        header_professores: [
            {text: "Código", sortable: true, value: 'codigo', class: 'subtitle-1'},
            {text: "Nome", value: 'nome', class: 'subtitle-1'},
            {text: "Email", value: 'email', class: 'subtitle-1'}
        ],
        footer_props: {
            "items-per-page-text": "Mostrar",
            "items-per-page-options": [30, 50, -1],
        },
        token: "",
        turma: {},
        id : 0,
        filtrar:"",
        filtrar2:"",
        utilizador:{},
        show:false
      }
    },
    created: async function(){
        this.token = localStorage.getItem("token")
        this.utilizador = JSON.parse(localStorage.getItem("utilizador"))
        this.codProfessor = this.codSupervisor;
        this.getEscolas(this.escola);
        this.getProfessoresSupervisionados();
    },
    methods: {
      adicionarSupervisionados: async function(){
          Swal.fire({
            title: 'Tem a certeza que pretende adicionar os professores selecionados à lista de supervisionados?',
            showDenyButton: true,
            confirmButtonColor: '#009263',
            confirmButtonText: `Sim`,
            denyButtonText: `Não`,
          }).then(async (result) => {
            if (result.isConfirmed) {
            var body = {
              codigosProfs: this.selected.map(p => p.codigo)
            }
            await axios.post(h + "professores/" + this.codProfessor + "/supervisionados?token=" + this.token, body)
            for(let i = 0; i < this.selected.length; i++){
              const prof = this.selected[i];
              if(prof){
                this.professoresSupervisionados.push(prof);
              }
            }
            this.selected = []
            this.getProfessoresFromEscola();
            }
          })
      },
      removeSupervisionados: async function(){
          Swal.fire({
              title: 'Tem a certeza que pretende remover os professores selecionados?',
              showDenyButton: true,
              confirmButtonColor: '#009263',
              confirmButtonText: `Sim`,
              denyButtonText: `Não`,
            }).then(async (result) => {
              if (result.isConfirmed) {
                var body = {
                  codigosProfs: this.selected2.map(p => p.codigo)
                }
                await axios.put(h + "professores/" + this.codProfessor + "/apagar_supervisionados?token=" + this.token, body);
                for(let i = 0; i < this.selected2.length; i++){
                  const selected = this.selected2[i];
                  if(selected){
                    const index = this.professoresSupervisionados.indexOf(selected);
                    if(index > -1){
                      this.professoresSupervisionados.splice(index, 1);
                    }
                  }
                }
                this.selected2 = []
                this.getProfessoresFromEscola()
              }
        })
      },
      atualizaAlunos: async function(){
        const response = await axios.get(h + "turmas/" + this.turma.turma + "/alunos?codprofessor="+ this.turma.idprofessor + "&alunosAtuais=true" + "&token=" + this.token)
        this.alunosTurmaAtual = response.data
        response = await axios.get(h + "turmas/" + this.turma2 + "/alunos?codprofessor="+ this.utilizador.codigo + "&alunosAtuais=true" + "&token=" + this.token)
        this.alunosOutraTurma = response.data
      },
      getProfessoresSupervisionados: async function(){
        const response = await axios.get(h + "professores/" + this.codProfessor + "/supervisionados/?token=" + this.token);
        this.professoresSupervisionados = response.data;
      },
      getProfessoresFromEscola: async function(){
        const codigoEscola = this.escolaSel ? this.escolaSel.cod : null;
        if(codigoEscola){
          const response = await axios.get(h + "escolas/" + codigoEscola + "/professores/?token=" + this.token);
          const aux = response.data;
          this.professoresEscola = [];
          for(let i = 0; i < aux.length; i++){
            const prof = aux[i];
            if(!this.professoresSupervisionados.find(e => e.codigo === prof.codigo) && this.codProfessor !== prof.codigo)
              this.professoresEscola.push(prof);
          }
        }
      },
      getEscolas: async function(codEscola = null){
        const response = await axios.get(h + "escolas/?token=" + this.token)
        this.escolas= response.data;
        if(codEscola){
          const escola = this.escolas.find(e => e.cod == codEscola);
          if(escola)
            this.escolaSel = escola;
          else 
            this.escolaSel = (this.escolas && this.escola.length) ? this.escolas[0] : null;
        }
        else{
          this.escolaSel = (this.escolas && this.escola.length) ? this.escolas[0] : null;
        }
        this.getProfessoresFromEscola();
      }
    }
  }
</script>

<style scoped>
.transparent {
   background-color: white!important;
   opacity: 0.65;
   border-color: transparent!important;
 }

 .list-elements{
  min-height: 700px;
 }
</style>