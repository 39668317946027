<template>
  <v-app id="inspire">
    <v-main class="grey lighten-3">
        <v-container>
            <v-card class="pa-5">
                <v-container >
                    <center><v-icon large color="#009263">mdi-home-analytics</v-icon></center>
                    <v-card-title primary-title class="justify-center green--text">
                        Desempenho nos PTOs
                    </v-card-title>
                    <center>
                        <v-btn v-if="!showAjuda" text @click="showAjuda=!showAjuda"><span>Mostrar Ajuda</span><v-icon color="#009263"> mdi-help-circle </v-icon> </v-btn>
                        <v-btn v-else text @click="showAjuda=!showAjuda">Esconder Ajuda</v-btn> 
                    </center>
                    <v-slide-y-transition>
                        <v-card v-show="showAjuda" class="elevation-6 pa-3" style="border: 2px solid green !important;" color="grey lighten-3">
                            <v-row class="justify-center">
                                <v-col cols="12">
                                    <span> 1. Podes escolher o ano letivo ou o intervalo de tempo para o qual pretendes visualizar o teu desempenho. </span>
                                </v-col>
                                <v-col cols="12">
                                    <span> 2. Podes escolher o PTO sobre o qual pretendes visualizar as estatísticas de desempenho. Poderás visualizar para todos os PTOS
                                        ou para um PTO em específico. Apenas irão estar disponíveis os PTOS que tentaste resolver.
                                    </span>
                                </v-col>
                                <v-col cols="9">
                                    <v-card class="mx-auto" color="grey lighten-4">
                                        <center> <h3 class="green--text"> Legendas </h3> </center>
                                        <ul> 
                                            <li> <span> <b>#PTOS</b> - Apenas visível se escolheres ver "Todos" e representa o número total de PTOS que tentaste resolver. </span></li>
                                            <li> <span> <b>NQRC</b> - Número de questões resolvidas corretamente; </span> </li>
                                            <li> <span> <b>NQR</b> - Número de questões resolvidas; </span> </li>
                                            <li> <span> <b>Acerto (%)</b> - Percentagem de acerto (NQRC/NQR); </span> </li>
                                        </ul>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-slide-y-transition>
                        <v-container :style="widthParams">
                            <v-combobox
                                v-model="tpcSel"
                                :items="tpcs"
                                item-text="description"
                                label="PTO"
                                color="green"
                                @change="onTpcChange()"
                            ></v-combobox>
                            <v-combobox
                                id="anos"
                                v-model="anoLetivo"
                                label="Ano Letivo"
                                color="#009263"
                                :items="anosLetivos"
                                @change="onAnoChange"
                            ></v-combobox>
                        
                            <v-row>
                                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" >
                                <v-text-field @change="onDataInChange" color="#009263" v-model="dataInicio" label="Data Inicio" type="date" :format="format" required></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" >
                                    <v-text-field @change="onDataFimChange" color="#009263" v-model="dataFim" label="Data Fim" type="date" :format="format" required></v-text-field>
                                </v-col>
                            </v-row>
                            </v-container>
                            <br>
                            <v-card class="pa-4 elevation-5" style="border: 2px solid green !important;">
                                <v-container v-if="resultadosGlobais == undefined">
                                    <center><v-icon large color="#009263"> mdi-home-analytics </v-icon></center>
                                    <br>
                                <center> 
                                    <span :style="styleP"> 
                                        Ainda não preencheste os campos necessários para veres resultados ou nunca 
                                        resolveste este PTO.
                                    </span> 
                                </center>
                                </v-container>
                                <v-container v-else>
                                    <center><v-icon large color="#009263"> mdi-home-analytics </v-icon></center>
                                    <v-card-title class="justify-center" v-if="this.tpcSel"><span :style="styleP"> {{this.tpcSel.description}} </span></v-card-title>
                                    <br>
                                    <v-row class="justify-center">
                                        <v-col v-if="tpcSel && tpcSel.id == -1" cols="12" xs="12" sm="6" md="4" lg="3" xl="3">
                                            <v-card  style="background-color:#3ab040">
                                                <v-card-title class="white--text justify-center">
                                                    <span :style="styleP"> #TPCS </span>
                                                </v-card-title>
                                                <center>
                                                <v-card-text class="white--text justify-center">
                                                    {{resultadosGlobais.tpcs}}
                                                </v-card-text>
                                                </center>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="3">
                                            <v-card style="background-color:#009263">
                                                <v-card-title class="white--text justify-center">
                                                    <span :style="styleP"> NQRC </span>
                                                </v-card-title>
                                                <center>
                                                <v-card-text class="white--text justify-center">
                                                    {{resultadosGlobais.ncertas}}
                                                </v-card-text>
                                                </center>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="3">
                                            <v-card  style="background-color:#3ab040">
                                                <v-card-title  primary-title class="white--text justify-center">
                                                    <span :style="styleP"> NQR </span>
                                                </v-card-title>
                                                <center>
                                                <v-card-text class="white--text justify-center">
                                                    {{resultadosGlobais.ntotal}}
                                                </v-card-text>
                                                </center>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="3">
                                            <v-card  style="background-color:#009263">
                                                <v-card-title  primary-title class="white--text justify-center">
                                                   <span :style="styleP"> Acerto </span>
                                                </v-card-title>
                                                <center>
                                                <v-card-text class="white--text justify-center">
                                                    {{resultadosGlobais.acerto}}%
                                                </v-card-text>
                                                </center>
                                            </v-card>
                                        </v-col>
                                        <!-- <v-col v-if="app!='Todas'" cols="12"  xs="12" sm="12" md="12" lg="12" xl="12">
                                            <center>
                                                <v-btn v-if="appTarefa" class="white--text" color="#009263" @click="getTarefas">
                                                    <v-icon color="white">mdi-eye</v-icon> por tarefa
                                                </v-btn>
                                                <br v-if="appTarefa">
                                                <br>
                                                <v-btn v-if="!xs" class="white--text" color="#009263" @click="verTodos()">Ver todos estes resultados</v-btn>
                                                <v-btn v-else class="white--text" color="#009263" @click="verTodos()">Ver todos</v-btn>
                                            </center>
                                        </v-col> -->
                                    </v-row>                                    
                                </v-container>
                            </v-card>
                            <!-- <v-dialog v-model="dialogTarefas">
                                <TarefasApps v-if="dialogTarefas" :propsTarefas="propsTarefas"/>
                            </v-dialog>
                            <v-dialog v-model="dialogDia">
                                <AppDiaAluno v-if="dialogDia" :resultados="appPorDia" :app="app" />
                            </v-dialog> -->
                </v-container>
            </v-card>
        </v-container>
    </v-main>
  </v-app> 
</template>



<script>
import axios from "axios"
const h = require("@/config/hosts").hostAPI
const hostTpcs = require("@/config/hosts").hostAPITpcs
const anosletivos2 = require("@/config/confs").anosletivos2
const anoletivoAtual = require("@/config/confs").anoletivo2
import AppDiaAluno from "@/components/Apps/AppDiaAluno.vue"
import TarefasApps from "@/components/Apps/TarefasRealizadasAluno.vue"


  export default {
    data(){
      return {
        showAjuda: false,
        dialogDia: false,
        dialogTarefas: false,
        appPorDia: [],
        token: "",
        tpcSel: null,
        dataInicio: "2022-09-01",
        dataFim: "2023-09-01",
        utilizador : {},
        header_resultados: [
            {text: "Pontuação Obtida", value: 'pontuacao', class: 'subtitle-1'},
            {text: "Data", value: 'data', class: 'subtitle-1'},
            {text: "Horário", value: 'horario', class: 'subtitle-1'},
            ],
        footer_props: {
            "items-per-page-text": "Mostrar",
            "items-per-page-options": [50, 100, 200, -1],
            "items-per-page-all-text": "Todos"
        },
        filtrar : "",
        anosLetivos: anosletivos2,
        anoLetivo: anoletivoAtual,
        tpcs:[],
        resultadosGlobais:undefined,
        styleP: 'font-size:20px',
        styleF: 'font-size:15px',
        widthParams: 'width:70%'
      }
    },
    components:{
        AppDiaAluno,
        TarefasApps
    },
    created: async function(){
        this.token = localStorage.getItem("token")
        this.utilizador = JSON.parse(localStorage.getItem("utilizador"))
        await this.onAnoChange()
        this.resize()
    },
    mounted: function(){
        window.onresize = () => {
            this.resize()
        }
    },
    computed:{
        size(){
            if (this.$vuetify.breakpoint.xs) return 'x-small'
            else if(this.$vuetify.breakpoint.sm) return 'small'
            
            return 'medium'
        },
        xs(){
            if (this.$vuetify.breakpoint.xs) return true
            return false
        }
    },
    methods: {
      resize(){
          if (this.$vuetify.breakpoint.xs) {this.styleP='font-size:15px'; this.widthParams = 'width:100%';}
          else if(this.$vuetify.breakpoint.sm) {this.styleP= 'font-size:17px'; this.widthParams = 'width:85%'}
          else {this.styleP ='font-size:20px'; this.widthParams = 'width:70%';}
      },
      atualizaTpcs: async function(){
          if(this.dataInicio && this.dataFim){
            var response = await axios.get(hostTpcs + "alunos/"+ this.utilizador.user + "/tpcs" +
                                            "?dataInicio=" + this.dataInicio + "&dataFim=" + this.dataFim + "&token=" + this.token)
            if(response && response.data && response.data.length){
                this.tpcs = [{id: -1, description: "Todos"}, ...response.data];
            }
            else {
                this.tpcs = [];
                this.tpcSel = null;
                this.resultadosGlobais = undefined;
            }
            if(this.tpcSel){
                if(!this.tpcs.find(t => t.id === this.tpcSel.id)){
                    this.tpcSel = null;
                    this.resultadosGlobais = undefined;
                }
            }  
          }
          return;
      },
      format(value, event) {
        return moment(value).format('YYYY-MM-DD')
      },
      onAnoChange: async function(item){
          if(this.anoLetivo != ""){
             var aux = this.anoLetivo.split("/")
             this.dataInicio = aux[0] + "-09-01"
             this.dataFim = aux[1] + "-09-01"
             this.atualizaTpcs()
          }
      },
      onTpcChange: async function(item){
          if(this.tpcSel){
              this.atualizaConteudo()
          }
      },
      onDataInChange: async function(item){
          if(this.dataInicio && this.dataInicio != ""){
              await this.atualizaTpcs()
              this.atualizaConteudo()
          }
      },
      onDataFimChange: async function(item){
          if(this.dataFim && this.dataFim != ""){
              await this.atualizaTpcs()
              this.atualizaConteudo()
          }
      },
      getTodas: async function(){
        const response = await axios.get(hostTpcs + "alunos/" + this.utilizador.user
                                        + "/?dataInicio=" + this.dataInicio + "&dataFim=" + this.dataFim
                                        + "&token=" + this.token);
        return response;
      },
      getTpc: async function(tpcid){
        const response = await axios.get(hostTpcs + "alunos/" + this.utilizador.user
                                        + "/?dataInicio=" + this.dataInicio + "&dataFim=" + this.dataFim
                                        + "&tpcid=" + tpcid + "&token=" + this.token);
        return response;
      },
      atualizaConteudo: async function(){
            if(this.tpcSel && this.dataInicio && this.dataFim){
                this.loading = true
                if(this.tpcSel.id == -1){
                    // todos
                    var response = await this.getTodas();
                    this.resultadosGlobais = response.data;
                }
                else{
                    // tpc especifico
                    var response = await this.getTpc(this.tpcSel.id);
                    this.resultadosGlobais = response.data;
                    
                }
                // this.loading = false;
          } 
      },
    }
  }
</script>