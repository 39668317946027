var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-5"},[_c('v-container',[_c('v-card-title',{staticClass:"justify-center green--text",attrs:{"primary-title":""}},[_vm._v(" Minhas Turmas ")]),_c('center',[(_vm.turmasAnoLetivo<_vm.limiteTurmas)?_c('v-btn',{staticClass:"white--text",staticStyle:{"background-color":"#009263"},on:{"click":function($event){return _vm.criarTurma()}}},[_c('v-icon',[_vm._v(" mdi-book-plus ")]),_vm._v(" Criar Turma ")],1):_c('v-row',{staticClass:"justify-center",staticStyle:{"display":"flex","align-items":"center"}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-alert-outline ")]),_c('span',[_vm._v(" Não pode criar mais turmas, uma vez que atingiu o limite deste ano letivo ("+_vm._s(_vm.limiteTurmas)+"). ")])],1)],1),_c('v-combobox',{attrs:{"id":"anoletivo","label":"Ano Letivo","prepend-icon":"mdi-counter","color":"#009263","items":_vm.anosletivos},on:{"change":function($event){return _vm.getTurmas()}},model:{value:(_vm.anoletivo),callback:function ($$v) {_vm.anoletivo=$$v},expression:"anoletivo"}}),_c('v-text-field',{attrs:{"label":"Filtrar","prepend-icon":"mdi-magnify","color":"#009263","single-line":""},model:{value:(_vm.filtrar),callback:function ($$v) {_vm.filtrar=$$v},expression:"filtrar"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.header_turmas,"items":_vm.turmas,"footer-props":_vm.footer_props,"search":_vm.filtrar},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',[_vm._v(_vm._s(row.item.anoletivo))]),_c('td',[_vm._v(_vm._s(row.item.turma))]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{on:{"click":function($event){return _vm.verTurma(row.item.id)}}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar os alunos pertencentes à turma.")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{on:{"click":function($event){return _vm.editarTurma(row.item.id)}}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Efetuar transferências de alunos, no qual envolverá esta turma.")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{on:{"click":function($event){return _vm.apagarTurma(row.item.id)}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Apagar esta turma, caso não haja alunos pertencentes à turma, entre outros.")])])],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }