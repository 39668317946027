<template>
    <v-card class="elevation-5 pa-5" background-color="gray">
        <v-container class="justify-center">
                <v-row class="pa-0">
                    <v-col class="pa-0 text-right">
                        <v-btn class="pa-0 text-none" text plain style="color: #009263; min-width: 112px;" @click="emailConfirmed()"> Enviar e-mail</v-btn>
                    </v-col>
                </v-row>
                <v-text-field label="Para" placeholder="Para" v-model="emailTo" color="#009263" required/>
                <v-text-field label="Cc" placeholder="Cc" v-model="emailCC" color="#009263" required/>
                <v-text-field label="Assunto" placeholder="Assunto" v-model="subject" color="#009263" required/>
                <v-textarea class="pa-0" color="#009263" outlined rounded
                        v-model="message" name="Mensagem" label="Mensagem"
                ></v-textarea>
        </v-container>
    </v-card>
</template>

<script>

export default {
  components: {
  },
  props: [
    'subjectProp',
    'messageProp', 
    'emailToProp',
    'emailCCProp',
  ],
  watch : {
  },
  computed: {
  },
  data(){
    return {
        subject: "",
        message: "",
        emailTo: "",
        emailCC: ""
    }
  },
  created() {
    this.subject = this.subjectProp || "";
    this.message = this.messageProp || "";
    this.emailTo = this.emailToProp || "";
    this.emailCC = this.emailCCProp || "";
  }, 
  methods:{
    emailConfirmed(){
        if(this.emailTo && this.subject){
            this.$emit("emailConfirmed", { 
                emailTo: this.emailTo,
                emailCC: this.emailCC,
                subject: this.subject,
                message: this.message
            });
        }
    }
  }
}
</script>