<template>
  <v-app id="inspire">
    <v-main class="grey lighten-3">
        <v-container>
            <v-card class="pa-5">
                <v-container>
                    <v-card-title primary-title class="justify-center green--text">
                        Monitorização de PTO por turmas do professor ({{this.codProf}})
                    </v-card-title>
                    
                    <br>
                    <center>
                        <v-btn v-if="!show" text @click="show=!show"><span>Mostrar Ajuda</span><v-icon color="#009263"> mdi-help-circle </v-icon> </v-btn>
                        <v-btn v-else text @click="show=!show">Esconder Ajuda</v-btn> 
                    </center>
                    <v-slide-y-transition>
                      <v-card v-show="show" class="elevation-6 pa-3" style="border: 2px solid green !important;" color="grey lighten-3">
                        <v-row class="justify-center">
                          <v-col cols="12">
                          <span> 1. Pode escolher uma das suas turmas através da seleção no campo "Turma". De referir que apenas estão disponíveis
                            as turmas do ano letivo atual. </span>
                          </v-col>
                          <v-col cols="12">
                            <span> 2. Escolha o trabalho de casa relativamente ao qual deseja visualizar 
                                dados estatísticos de cada um dos seus alunos que resolveu. 
                                Apenas os trabalhos de casa marcados no intervalo de tempo definido estão disponíveis para 
                                monitorização. A ordem dos trabalhos de casa estão ordenados pela data final de forma descrescente.
                            </span>
                          </v-col>
                          <v-col cols="12">
                            <span> 3. Pode alterar o intervalo de tempo pretendido, selecionando uma data inicial diferente ou uma data final diferente. </span> 
                          </v-col>
                          <v-col cols="12">
                            <span> 4. Caso pretenda uma monitorização sobre um ano letivo específico, pode selecionar esse ano letivo. </span> 
                          </v-col>
                          <v-col cols="12">
                            <span> 5. Tendo os campos referidos escolhidos e os dados apresentados, poderá exportar para pdf através do botão 
                                <v-btn small class="white--text" style="background-color: #009263;"> <v-icon> mdi-pdf-box </v-icon> Exportar </v-btn>. 
                            </span> 
                          </v-col>
                          <v-col cols="9">
                              <v-card class="mx-auto" color="grey lighten-4">
                                  <center> <h3 class="green--text"> Legenda da Tabela </h3> </center>
                                  <ul> 
                                      <li> <span> <b>N.º</b> - Número do aluno; </span> </li>
                                      <li> <span> <b>Nome</b> - Nome do aluno; </span> </li>
                                      <li> <span> <b>#PTO</b> - Número de PTO realizados pelo aluno (apenas disponível quando escolhe todos as PTOs); </span> </li>
                                      <li> <span> <b>NQRC</b> - Número total de questões resolvidas corretamente; </span> </li>
                                      <li> <span> <b>NQR</b> - Número total de questões resolvidas; </span> </li>
                                      <li> <span> <b>Acerto(%)</b> - Percentagem de acerto (NQRC/NQR); </span> </li>
                                  </ul>
                              </v-card>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-slide-y-transition>
                        <br v-if="items.length>0">
                        <v-row class="justify-center align-center">
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <DropdownExport 
                                :isVisibleProp="items.length > 0" 
                                :isPDFEnabledProp="true" 
                                :isCSVEnabledProp="true"
                                :itemsCSVToExportProp="items"
                                :fieldsToCSVExportProp="fieldsToCSVExport"
                                :filenameProp="filenameToExport"
                                @PDFClicked="exportPDF()"
                            >
                            </DropdownExport>
                        </v-col>
                        </v-row>
                        <center>
                        <v-container style="width:80%">
                        <v-card class="pa-5" >
                            <v-combobox
                                id="turmas"
                                v-model="turmaSel"
                                label="Turma"
                                color="green"
                                :items="turmas"
                                @change="onTurmaChange"
                            ></v-combobox>
                            <v-row v-if="tpcs" class="align-center">
                                <v-col cols="11">
                                    <v-combobox
                                        v-model="tpcSel"
                                        :items="tpcs"
                                        item-text="description"
                                        label="PTO"
                                        color="green"
                                        @change="onTpcChange()"
                                    ></v-combobox>
                                </v-col>
                                <v-col v-if="turmaSel && turmaSel != ''" cols="1">
                                    <v-btn icon color="#009263" @click="atualizaTpcs()">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-combobox
                                id="anos"
                                v-model="anoLetivo"
                                label="Ano Letivo"
                                color="green"
                                :items="anosLetivos"
                                @change="onAnoChange"
                            ></v-combobox>
                            <v-layout row class="text-xs-center" justify-center align-center>
                                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field @change="onDataInicioChange" v-model="dataInicio" label="Data Inicio" type="date" :format="format" required></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field @change="onDataFimChange" v-model="dataFim" label="Data Fim" type="date" :format="format" required></v-text-field>
                                </v-col>
                            </v-layout>
                            <v-row class="justify-center align-center">
                                <v-btn class="white--text" color="#009263" @click="atualizaConteudo()">
                                    <v-icon>mdi-refresh</v-icon>
                                    Atualizar
                                </v-btn>
                            </v-row>
                        </v-card>
                        </v-container>
                        </center>
                        <br>
                <v-container v-if="loading">
                    <center><v-img :src="require('@/assets/loading.gif')" width="150px" heigth="150px"> </v-img></center>
                </v-container>
                <v-container v-else>
                <v-text-field
                    v-model="filtrar"
                    label="Filtrar"
                    prepend-icon="mdi-magnify"
                    color="#009263"
                    single-line
                ></v-text-field>
                <v-data-table
                    class="elevation-4"
                    :headers="headers"
                    :items="items"
                    :footer-props="footer_props"
                    :search="filtrar"
                >
                    <!-- <template v-slot:item="row">
                        <tr>
                            <td>{{row.item.numero}}</td>
                            <td>{{row.item.nome}}</td>

                            <td>{{row.item.tentativas}}</td>
                            <td>{{row.item.ncertas}}</td>
                            <td>{{row.item.ntotal}}</td>
                            <td>{{row.item.acerto}}</td>
                        </tr>
                    </template> -->
                </v-data-table>
                </v-container>
                </v-container>
            </v-card>
        </v-container>
    </v-main>
  </v-app> 
</template>



<script>
import axios from "axios"
import jsPDF from 'jspdf' 
import 'jspdf-autotable'

const h = require("@/config/hosts").hostAPI
const hostTpcs = require("@/config/hosts").hostAPITpcs
const hypatiaImg = require("@/assets/hypatiamat.png")
const anosletivos2 = require("@/config/confs").anosletivos2
const anoletivoAtual = require("@/config/confs").anoletivo2
const anoletivoAtual2 = require("@/config/confs").anoletivo
import DropdownExport from '@/components/Common/DropdownExport';

  export default {
    name: 'TpcsTurma',
    components:{
        DropdownExport
    },
    data(){
      return {
        token: "",
        loading: false,
        tpcSel: null,
        filtrar:"",
        dataInicio: "2022-09-01",
        dataFim: "2023-09-01",
        turmaSel: "",
        utilizador : {},
        alunos:[],
        footer_props: {
            "items-per-page-text": "Mostrar",
            "items-per-page-options": [50, 100, 200, -1],
            "items-per-page-all-text": "Todos"
        },
        filtrar : "",
        anosLetivos:anosletivos2,
        anoLetivo: anoletivoAtual,
        tpcs:[],
        headers:[
            {text: "N.º", value: 'numero', class: 'subtitle-1'},
            {text: "Nome", value: 'nome', class: 'subtitle-1'},
            {text: "NQRC", value: 'ncertas', class: 'subtitle-1'},
            {text: "NQR", value: 'ntotal', class: 'subtitle-1'},
            {text: "Acerto(%)", value: 'acerto', class: 'subtitle-1'},
        ],
        headers_tpc:[
            {text: "N.º", value: 'numero', class: 'subtitle-1'},
            {text: "Nome", value: 'nome', class: 'subtitle-1'},
            {text: "NQRC", value: 'ncertas', class: 'subtitle-1'},
            {text: "NQR", value: 'ntotal', class: 'subtitle-1'},
            {text: "Acerto(%)", value: 'acerto', class: 'subtitle-1'},
        ],
        headers_todos:[
            {text: "N.º", value: 'numero', class: 'subtitle-1'},
            {text: "Nome", value: 'nome', class: 'subtitle-1'},
            {text: "#PTO", value: 'tpcs', class: 'subtitle-1'},
            {text: "NQRC", value: 'ncertas', class: 'subtitle-1'},
            {text: "NQR", value: 'ntotal', class: 'subtitle-1'},
            {text: "Acerto(%)", value: 'acerto', class: 'subtitle-1'},
        ],
        fieldsToCSVExport: {
            numero: { title: 'N.º'},
            nome: { title: 'Nome' },
            ncertas: { title: 'NQRC' },
            ntotal: { title: 'NQR' },
            acerto: { title: 'Acerto(%)' }
        },
        fieldsToCSVExportTPC: {
            numero: { title: 'N.º'},
            nome: { title: 'Nome' },
            ncertas: { title: 'NQRC' },
            ntotal: { title: 'NQR' },
            acerto: { title: 'Acerto(%)' }
        },
        fieldsToCSVExportTodos: {
            numero: { title: 'N.º'},
            nome: { title: 'Nome' },
            tpcs: { title: '#PTO'},
            ncertas: { title: 'NQRC' },
            ntotal: { title: 'NQR' },
            acerto: { title: 'Acerto(%)' }
        },
        items: [],
        codProf:"",
        turmas:[],
        turmaSel:"",
        show: false,
        nomeProf:"",
        propsTarefas: undefined,
        dialogTarefas: false,
        propsGrafico: {},
        dialogGrafico: false,
        numerosTurma: []
      }
    },
    created: async function(){
        this.token = localStorage.getItem("token");
        this.utilizador = JSON.parse(localStorage.getItem("utilizador"));
        this.codProf = this.$route.params.idprofessor;
        const anoAux = anoletivoAtual2.split("/");
        const anoAtual = anoAux[0];
        this.atualizaTpcs();
        const response = await axios.get(`${h}professores/${this.codProf}/turmas?ano=${anoAtual}&token=${this.token}`);
        let i = 0;
        for(i = 0; i < response.data.length; i++){
          this.turmas.push(response.data[i].turma);
        }
        
        const response2 = await axios.get(h + "professores/codigos/" + this.codProf + "/?token=" + this.token);
        this.nomeProf = response2.data.nome;

        if(this.$route.query.anoLetivo && this.$route.query.dataInicio && this.$route.query.dataFim){
            this.dataInicio = this.$route.query.dataInicio;
            this.dataFim = this.$route.query.dataFim;
            this.anoLetivo = this.$route.query.anoLetivo;
        }
        else{
            this.onAnoChange();
        }
    },
    computed:{
        filenameToExport(){
            if(!this.tpcSel || !this.turmaSel) return "";
            const isAllTpcs = (this.tpcSel.id == -1);
            const tpcDescriptionPDFTitle = isAllTpcs ? 'Todos' : this.tpcSel.id;
            return `PTO-${tpcDescriptionPDFTitle}-${this.turmaSel}.pdf`;
        }
    },
    methods: {
      format(value, event) {
        return moment(value).format('YYYY-MM-DD');
      },
      atualizaTpcs: async function(){
          if(this.turmaSel && this.turmaSel != ""){
            var response = await axios.get(hostTpcs + "turmas/" + this.turmaSel + "/tpcs?codprofessor=" + this.codProf
                                                + "&dataInicio=" + this.dataInicio + "&dataFim=" + this.dataFim
                                                + "&token=" + this.token);
            if(response.data && response.data.length){
                this.tpcs = [{id: -1, description: "Todos"}, ...response.data];
            }
            else {
                this.tpcs = [];
                this.tpcSel = null;
                this.items = [];
            }
            if(this.tpcSel){
                if(!this.tpcs.find(t => t.id === this.tpcSel.id)){
                    this.tpcSel = null;
                    this.items = [];
                }
            }            
          }
          return;
      },
      onTurmaChange: async function(item){
          if(this.turmaSel && this.turmaSel != ""){
              this.atualizaTpcs();
              //this.atualizaConteudo()
          }
      },
      onAnoChange: async function(item){
          if(this.anoLetivo != ""){
             var aux = this.anoLetivo.split("/")
             this.dataInicio = `${aux[0]}-09-01`;
             this.dataFim = `${aux[1]}-09-01`;
             await this.atualizaTpcs()
             this.atualizaConteudo()
          }
      },
      onTpcChange: async function(item){
          if(this.tpcSel){
            this.atualizaHeaders()
            this.atualizaConteudo()
          }
      },
      atualizaHeaders: async function(){
          if(this.tpcSel && this.tpcSel.id == -1){
            // todos
            this.headers = this.headers_todos;
            this.fieldsToCSVExport = this.fieldsToCSVExportTodos;
          }
          else {
            this.headers = this.headers_tpc;
            this.fieldsToCSVExport = this.fieldsToCSVExportTPC;
          }
      },
      onDataInicioChange: async function(item){
          if(this.dataInicio){
              await this.atualizaTpcs();
              this.atualizaConteudo();
          }
      },
      onDataFimChange: async function(item){
        if(this.dataFim){
            await this.atualizaTpcs();
            this.atualizaConteudo();
        }
      },
      getTodas: async function(){
        const response = await axios.get(hostTpcs + "turmas/" + this.turmaSel
                                        + "/?dataInicio=" + this.dataInicio + "&dataFim=" + this.dataFim
                                        + "&codprofessor=" + this.codProf + "&token=" + this.token);
        return response;
      },
      getTpc: async function(tpcid){
        const response = await axios.get(hostTpcs + "turmas/" + this.turmaSel
                                        + "/?dataInicio=" + this.dataInicio + "&dataFim=" + this.dataFim
                                        + "&codprofessor=" + this.codProf + "&tpcid=" + tpcid
                                        + "&token=" + this.token);
        return response;
      },
      atualizaConteudo: async function(){
            if(this.tpcSel && this.dataInicio && this.dataFim && this.turmaSel){
                this.loading = true
                if(this.tpcSel.id == -1){
                    // todos
                    var response = await this.getTodas();
                    this.items = response.data;
                }
                else{
                    // tpc especifico
                    var response = await this.getTpc(this.tpcSel.id);
                    this.items = response.data;
                    
                }
                this.loading = false;
          } 
      },
      exportPDF: async function(){
        if(!this.tpcSel || !this.turmaSel) return;

        const isAllTpcs = (this.tpcSel.id == -1);
        const tpcDescriptionPDFTitle = isAllTpcs ? 'Todos' : this.tpcSel.id;
        const tpcDescriptionLabel = isAllTpcs ? 'Todos' : this.tpcSel.description;
    
        let doc = new jsPDF({
        });

        let xImage = doc.internal.pageSize.getWidth() / 4;
        let ytotal = 0;
        const pdfName =  `TPCS-${tpcDescriptionPDFTitle}-${this.turmaSel}.pdf`;
        
        doc.addImage(hypatiaImg, 'PNG', xImage, 4);
        //doc.text("Jogo:")
        //doc.text("Estatisticas dos alunos sobre o jogo " + this.jogo + "da turma " + this.turmaSel, doc.internal.pageSize.getWidth() / 2, 8, null, null, 'center')
        doc.setFontSize(11);
        
        doc.text("Professor: " + this.nomeProf, 15, 50);
        doc.text("Turma: " + this.turmaSel, 130, 50);
        doc.text("Período: " + this.dataInicio + " até " + this.dataFim, 15, 56);
        doc.text("TPC: " + tpcDescriptionLabel, 15, 62);
        let listaRes = [];
        let total =["Todos", "Todos", 0, 0, 0];
        if(isAllTpcs) total =["Todos", "Todos", 0, 0, 0, 0];
        for(var i = 0; i<this.items.length; i++){
            // {text: "N.º", value: 'numero', class: 'subtitle-1'},
            // {text: "Nome", value: 'nome', class: 'subtitle-1'},
            // {text: "#TPCS", value: 'tpcs', class: 'subtitle-1'},
            // {text: "Tentativas", value: 'tentativas', class: 'subtitle-1'},
            // {text: "NQRC", value: 'ncertas', class: 'subtitle-1'},
            // {text: "NQR", value: 'ntotal', class: 'subtitle-1'},
            // {text: "Acerto(%)", value: 'acerto', class: 'subtitle-1'},
            let aux = [];
            aux.push(this.items[i].numero);
            aux.push(this.items[i].nome);
            let indexToTotal = 2;
            if(isAllTpcs) {
                aux.push(this.items[i].tpcs);
                if(total[indexToTotal] < this.items[i].tpcs){
                    total[indexToTotal] = this.items[i].tpcs;
                }
                indexToTotal++;
            }
            aux.push(this.items[i].ncertas);
            total[indexToTotal++] += this.items[i].ncertas;
            aux.push(this.items[i].ntotal)
            total[indexToTotal++] += this.items[i].ntotal;
            aux.push(this.items[i].acerto);

            listaRes.push(aux);
        }

        if(isAllTpcs) total[5] = Math.round((total[3]/total[4]) * 100); 
        else total[4] = Math.round((total[2]/total[3]) * 100);

        listaRes.push(total);
        let headers = ['N.º', "Nome", 'NQRC', "NQR", "Acerto(%)"];
        if(isAllTpcs) headers = ['N.º', "Nome", '#PTO', 'NQRC', "NQR", "Acerto(%)"];
        doc.autoTable({
            head: [headers],
            body: listaRes,
            headStyles: { fillColor: [0, 146, 99] },
            margin:{top: 70, bottom: (isAllTpcs ? 30 : 26)},
            didDrawPage: function (data) {
                    // Reseting top margin. The change will be reflected only after print the first page.
                    data.settings.margin.top = 10;
                    ytotal = doc.internal.pageSize.getHeight()
                    doc.setFontSize(8)
                    let ydecrement = isAllTpcs ? 26 : 22;
                    let indexYDecrement = 0;
                    doc.text("Legenda:" , 10, ytotal - (ydecrement - (4 * indexYDecrement++)))
                    if(isAllTpcs){
                        doc.text("#PTO - N.º de PTO que o aluno realizou", 10, ytotal - (ydecrement - (4 * indexYDecrement++)))
                    }
                    doc.text("NQRC - N.º de questões resolvidas corretamente", 10, ytotal - (ydecrement - (4 * indexYDecrement++)))
                    doc.text("NQR- N.º de questões resolvidas", 10, ytotal - (ydecrement - (4 * indexYDecrement++)))
                    doc.text("Acerto (%) - (NQRC/NQR)*100", 10, ytotal - (ydecrement - (4 * indexYDecrement++)))
            },
            willDrawCell: function (data) {
                    var rows = data.table.body;
                    if (data.row.index === rows.length - 1) {
                        doc.setFillColor(5, 179, 123);
                        doc.setTextColor(255, 255, 255)
                    }
                },
        });
        
        doc.save(pdfName);
      }
    },
    
    
      
  }
</script>

<style>
.style-negativo {
  background-color: #f26755
}
.style-positivo {
  background-color: #10de16
}
</style>