import axios from "axios";
import jsPDF from 'jspdf';
const h = require("@/config/hosts").hostAPI;
const hostCampeonatosCertificados = require("@/config/hosts").hostCampeonatosCertificados;
const hostAPICampeonatos = require("@/config/hosts").hostAPICampeonatos;
const dataCertificados = require("@/config/confs").campeonatosCertificadosDataChange;

export function isCampeonatoComCertificadosAntigo(dataCampeonato){
    let result = false;
    if(dataCampeonato){
        // retirar horas da data para não interferir com a comparação
        const dataInicioSemHorasAux = dataCampeonato.split(' ');
        if(dataInicioSemHorasAux && dataInicioSemHorasAux.length){
            result = (new Date(dataInicioSemHorasAux[0]) < dataCertificados)
        }
    }
    return result;
}

export async function downloadCertificadoNewWay(infoCampeonato, infoAluno, token){  
    let success = false;
    try{
        const jwtQ = `token=${token}`;
        const codigoCamp = infoCampeonato.codigo;
        const jogoCamp = infoCampeonato.jogo;         
        const infoCertificado = await axios.get(`${hostCampeonatosCertificados}${codigoCamp}/certificado-info?${jwtQ}`);
        const campeonatoTarget = await axios.get(`${hostCampeonatosCertificados}${codigoCamp}/target?jogo=${jogoCamp}&${jwtQ}`);
        const infoJogo = await axios.get(`${hostCampeonatosCertificados}jogos/${jogoCamp}?${jwtQ}`);
        const resAlunoCampeonato = {
            classificacao : infoAluno.posHypatia
        }
        const jogoCampeonato = campeonatoTarget.data;
        const dataJ = infoJogo.data;
        const info = infoCertificado.data;
    
        if(info){
            const id = info.id
            const frase = info.frase1
            const bannersInfo = await axios.get(`${hostCampeonatosCertificados}${id}/banners?${jwtQ}`);
            const bannersData = bannersInfo.data
            if(bannersData.length == 2){
    
                const banner1url = bannersData.find(e => e.banner === "banner1").url
                const banner2url = bannersData.find(e => e.banner === "banner2").url
    
                const anoJogo = dataJ.texto.split('(')[1].split('.')[0]
    
                const di = new Date(infoCampeonato.di);
                const df = new Date(infoCampeonato.df);
    
                const diDay = di.getDate();
                const dfDay = df.getDate();
                const diMonth = di.toLocaleString('pt-PT', { month: 'long' });
                const dfMonth = df.toLocaleString('pt-PT', { month: 'long' });
                const year = di.getFullYear();
    
                let dateString;
    
                if (diMonth !== dfMonth) {
                    dateString = `${diDay} de ${diMonth} a ${dfDay} de ${dfMonth} de ${year}`;
                } else if ((df - di) / (1000 * 60 * 60 * 24) >= 2) {
                    dateString = `${diDay} a ${dfDay} de ${diMonth} de ${year}`;
                } else {
                    if(diDay == dfDay)
                        dateString = `${dfDay} de ${dfMonth} de ${year}`
                    else
                        dateString = `${diDay} e ${dfDay} de ${diMonth} de ${year}`;
                }
    
                let completeText="";
                if(resAlunoCampeonato.classificacao == 1){
                    if(jogoCampeonato.tipo != ""){
                        completeText = "Certifica-se que " + infoAluno.nome + " foi a(o) campeã(o) na " + frase + " - "  + dataJ.descricao +
                        " - " + jogoCampeonato.tipo + ", para alunos do " + anoJogo + ".º ano de escolaridade, realizado no(s) dia(s) " + dateString + "."
                    }else{
                        completeText = "Certifica-se que " + infoAluno.nome + " foi a(o) campeã(o) na " + frase + " - "  + dataJ.descricao +
                        ", para alunos do " + anoJogo + ".º ano de escolaridade, realizado no(s) dia(s) " + dateString + "."
                    }
                }else{
                    if(jogoCampeonato.tipo != ""){
                        completeText = "Certifica-se que " + infoAluno.nome + " participou na " + frase + " - "  + dataJ.descricao +
                        " - " + jogoCampeonato.tipo + ", para alunos do " + anoJogo + ".º ano de escolaridade, realizado no(s) dia(s) " + dateString
                        + ", tendo ficado(a) colocado(a) na " + resAlunoCampeonato.classificacao + ".ª posição."     
                    }else{
                        completeText = "Certifica-se que " + infoAluno.nome + " participou na " + frase + " - "  + dataJ.descricao +
                        ", para alunos do " + anoJogo + ".º ano de escolaridade, realizado no(s) dia(s) " + dateString
                        + ", tendo ficado(a) colocado(a) na " + resAlunoCampeonato.classificacao + ".ª posição."     
                    }
                }
                let finalDate = "Braga, " + dfDay + " de " + dfMonth + " de " + year + "."
    
                const doc = new jsPDF('landscape');
                
                doc.addImage(hostAPICampeonatos + banner1url.slice(1), 'JPEG', 5, 5, doc.internal.pageSize.width - 10, 30);
                
                
                doc.setFontSize(18);
                doc.setFont('helvetica', 'normal');
                
                
                const textWidth = doc.internal.pageSize.width * 0.8;
                const textHeight = doc.internal.getFontSize() * 0.8;
                const lines = doc.splitTextToSize(completeText, textWidth);
                let textY = (doc.internal.pageSize.height - (lines.length * textHeight)) / 2 - 30;
                doc.text(completeText,(doc.internal.pageSize.width - textWidth) / 2, textY,{
                        maxWidth: textWidth,
                        lineHeightFactor: 2,
                        align: "justify"
                    })
                
                for (let i = 0; i < lines.length; i++) {
                    textY += textHeight
                }
    
    
                doc.text(finalDate,(doc.internal.pageSize.width) / 2 - 40, textY + 1)
    
                doc.addImage(hostAPICampeonatos + banner2url.slice(1), 'JPEG', 0, doc.internal.pageSize.height - 100, doc.internal.pageSize.width, 100);
    
                                        
                doc.setDrawColor("#009263")
                doc.setLineWidth(2)
                doc.rect(0, 0, doc.internal.pageSize.width , doc.internal.pageSize.height, 'S');
                const pdfContent = doc.output('bloburl');
    
                const blob = await fetch(pdfContent).then(res => res.blob());
                
                const url = URL.createObjectURL(blob);
    
                const a = document.createElement('a');
                a.href = url;
                a.download = 'Certificado.pdf';
    
                // Download
                a.click();
                success = true;
            }
        }
    }
    catch(error){
        console.log(error);
        return false;
    }
    
    return success;
}

export async function getAllJogosCampeonatos(token = ''){
    const response = await axios.get(`${hostCampeonatosCertificados}jogos?token=${token}`);
    return response.data;
}