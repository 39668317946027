var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-main',{staticClass:"grey lighten-3"},[_c('v-container',[_c('v-card',{staticClass:"justify-center"},[_c('center',[_c('v-icon',{attrs:{"x-large":"","color":"#009263"}},[_vm._v(" mdi-book-open-page-variant-outline ")]),_c('br'),_c('span',[_vm._v(" Caderneta de cromos ")])],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-combobox',{attrs:{"id":"anos","label":"Ano Letivo","color":"green","items":_vm.anosletivos},on:{"change":function($event){return _vm.getDataCromos()}},model:{value:(_vm.anoletivoSel),callback:function ($$v) {_vm.anoletivoSel=$$v},expression:"anoletivoSel"}})],1),(_vm.totalCromos > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('center',[_c('span',[_vm._v(" "+_vm._s(_vm.numeroCromosCompletados)+" / "+_vm._s(_vm.totalCromos)+" ")]),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm.percentagemCromos)+"% ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-progress-linear',{attrs:{"color":"cyan darken-2","rounded":"","value":_vm.percentagemCromos}})],1)],1)],1):_c('v-col',{attrs:{"cols":"12"}},[_c('center',[_c('span',[_vm._v(" Neste ano letivo, não existiram cromos para completar. ")])])],1),_vm._l((_vm.cromos),function(cromo){return _c('v-col',{key:cromo.id,attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-container',{staticClass:"text-center pa-0 ma-0"},[(_vm.naoPossuiCromo(cromo.id))?_c('center',[_c('div',{staticStyle:{"margin-top":"65px"}},[_c('v-img',{attrs:{"src":require('@/assets/cromos/cromoBack2.png'),"width":"150px"}},[_c('v-avatar',[_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(cromo.numero))])])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-information-outline ")])],1)]}}],null,true)},[_c('center',[_c('span',[_vm._v(_vm._s(cromo.nome))]),_c('br'),_c('span',[_vm._v(_vm._s(cromo.descricao))])])],1)],1)]):(!_vm.cromosCompletados.find(function (e) { return e.idcromo == cromo.id; }).virado)?_c('center',[_c('div',{staticStyle:{"margin-top":"65px"}},[_c('v-img',{attrs:{"src":require('@/assets/cromos/cromoBack2.png'),"width":"150px"},on:{"click":function($event){return _vm.abreCromo(cromo.id)}}},[_c('span',{staticStyle:{"color":"#009263"}},[_vm._v("Clique para abrir o cromo!")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-information-outline ")])],1)]}}],null,true)},[_c('center',[_c('span',[_vm._v(_vm._s(cromo.nome))]),_c('br'),_c('span',[_vm._v(_vm._s(cromo.descricao))])])],1)],1)]):_c('v-container',[_c('center',[(cromo.estrelas && _vm.verifyEstrelas(_vm.cromosCompletados.find(function (e) { return e.idcromo == cromo.id; })))?_c('div',[(cromo.estrelas)?_c('div',[_c('v-img',{attrs:{"width":"150px","src":require('@/assets/cromos/estrela'+_vm.cromosCompletados.find(function (e) { return e.idcromo == cromo.id; }).estrelasGanhas+'.png')}})],1):_vm._e(),_c('v-img',{attrs:{"src":_vm.hostImages + cromo.imagem,"width":"150px","heigth":"150px"},on:{"click":function($event){return _vm.abreCromo(cromo.id)}}}),_c('span',{staticClass:"caption"},[_vm._v("Clique no cromo, ganhou uma estrela.")])],1):_c('div',[(cromo.estrelas)?_c('div',[_c('v-img',{attrs:{"width":"150px","src":require('@/assets/cromos/estrela'+_vm.cromosCompletados.find(function (e) { return e.idcromo == cromo.id; }).estrelasGanhas+'.png')}}),_c('v-img',{attrs:{"src":_vm.hostImages + cromo.imagem,"width":"150px","heigth":"150px"}})],1):_c('div',{staticStyle:{"margin-top":"55px"}},[_c('v-img',{attrs:{"src":_vm.hostImages + cromo.imagem,"width":"150px","heigth":"150px"}})],1)]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-information-outline ")])],1)]}}],null,true)},[_c('center',[_c('span',[_vm._v(_vm._s(cromo.nome))]),_c('br'),_c('span',[_vm._v(_vm._s(cromo.descricao))])])],1)],1)],1)],1)],1)})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }