<template>
    <v-card class="elevation-5 pa-5" background-color="gray" rounded>
      <v-container>
            <!-- Aluno !-->
          <v-card-title primary-title class="justify-center green--text">
              Monitorização por aluno ({{aluno.user}} - {{aluno.nome}})
          </v-card-title>
          <v-container v-if="isLoading">
              <center><v-img :src="require('@/assets/loading.gif')" width="150px" heigth="150px"> </v-img></center>
          </v-container>
          <v-row v-else class="align-center justify-center" >
              <v-col cols="12">
                <center>
                  <v-btn v-if="!showAjuda" text @click="showAjuda=!showAjuda"><span>Mostrar Ajuda</span><v-icon color="#009263"> mdi-help-circle </v-icon> </v-btn>
                  <v-btn v-else text @click="showAjuda=!showAjuda">Esconder Ajuda</v-btn> 
                </center>
                <v-slide-y-transition>
                  <v-card v-show="showAjuda" class="elevation-6 pa-3" style="border: 2px solid green !important;" color="grey lighten-3">
                      <v-row class="justify-center">
                        <v-col cols="12">
                          <span> 1. Encontram-se disponíveis a quantidade de troféus que o aluno já conquistou. Além disso, pode visualizar 
                            a sua percentagem de acerto nas aplicações de conteúdo e quantas vezes já realizou jogos do Hypatiamat no ano letivo atual. Por último,
                            poderá visualizar também estatísticas sobre os PTOs realizados pelos alunos.
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <span> 2. Também é possível visualizar a sua prestação no <b>último</b> campeonato em que participou.
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <span> 3. As aplicações de conteúdo que realizou encontram-se também disponíveis, assim como as estatísticas 
                            gerais de cada aplicação. Além disso, pode clicar numa das aplicações (respetiva linha) e visualizar o desempenho
                            por dia.
                          </span>
                        </v-col>
                        <v-col cols="9">
                          <v-card class="mx-auto" color="grey lighten-4">
                              <center> <h3 class="green--text"> Legenda da Tabela Apps: </h3> </center>
                              <ul> 
                                  <li> <span> <b>NTRC</b> - Número de tarefas resolvidas corretamente; </span> </li>
                                  <li> <span> <b>NTR</b> - Número de tarefas resolvidas; </span> </li>
                                  <li> <span> <b>Acerto (%)</b> - Percentagem de acerto (NTRC/NTR); </span> </li>
                                  <li> <span> <b>#</b> - Frequência. </span> </li>
                                  <li> <div class="d-flex align-center"><hr color="#f26755" width="20px" heigth="15px" > <span> Acerto menor que 50%; </span> </div></li>
                                  <li> <div class="d-flex align-center"><hr color="#10de16" width="20px" heigth="15px" > <span> Acerto maior ou igual a 50%; </span> </div></li>
                              </ul>
                          </v-card>
                        </v-col>
                        <v-col cols="12">
                          <span> 4. Também pode visualizar os jogos que o aluno jogou, assim como as estatísticas 
                            gerais de cada jogo. Além disso, pode clicar num dos jogos (respetiva linha) e visualizar o seu desempenho no jogo
                            por dia. Apenas para o jogo CalcRapid não é possível.
                          </span>
                        </v-col>
                        <v-col cols="9">
                          <v-card class="mx-auto" color="grey lighten-4">
                              <center> <h3 class="green--text"> Legenda da Tabela Jogos: </h3> </center>
                              <ul> 
                                  <li> <span> <b>Min</b> - Mínimo de pontuação obtida no jogo; </span> </li>
                                  <li> <span> <b>Max</b> - Máximo de pontuação obtida no jogo; </span> </li>
                                  <li> <span> <b>Média</b> - Pontuação média obtida no jogo; </span> </li>
                                  <li> <span> <b>#</b> - Frequência. </span> </li>
                              </ul>
                          </v-card>
                        </v-col>
                        <v-col cols="12">
                          <span>
                            5. É possível observar também as propostas de trabalho online (PTO) realizadas pelo aluno, assim como 
                            as respetivas estatísticas acerca do PTO.
                          </span>
                        </v-col>
                        <v-col cols="9">
                          <v-card class="mx-auto" color="grey lighten-4">
                              <center> <h3 class="green--text"> Legenda da Tabela PTO: </h3> </center>
                              <ul> 
                                  <li> <span> <b>NQRC</b> - N.º de questões resolvidas corretamente; </span> </li>
                                  <li> <span> <b>NQR</b> - N.º de questões resolvidas; </span> </li>
                                  <li> <span> <b>Acerto (%)</b> - (NQRC/NQR)*100 </span> </li>
                              </ul>
                          </v-card>
                        </v-col>
                        <v-col cols="12">
                          <span>
                            6. Por último, está disponível o desempenho do aluno por cada campeonato de cálculo mental
                            que participou. Em cada campeonato, encontra-se disponiblizada a posição do aluno na turma, agrupamento
                            de escolas e Hypatiamat todo, tal como a pontuação obtida e o número de jogos realizado.
                          </span>
                        </v-col>
                      </v-row>
                  </v-card>
                </v-slide-y-transition>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                  <DropdownExport 
                      :isVisibleProp="true" 
                      :isPDFEnabledProp="true" 
                      :isCSVEnabledProp="false"
                      :isEmailEnabledProp="true"
                      @PDFClicked="exportPDF()"
                      @EmailClicked="exportEmailClicked()"
                  >
                  </DropdownExport>
              </v-col>
              <div id="html-export-1">
                <v-card style="border: 2px solid green !important;">
                <v-row class="align-center justify-center" no-gutters>
                  
                  <v-col cols="12">
                  <v-card-title class="justify-center green--text"> Troféus: </v-card-title>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <center>
                      <v-container class="d-flex align-center justify-center">
                        <div class="pr-3"><v-img :src="require('@/assets/diamante.png')" width="50px" heigth="50px"> </v-img></div>
                        <div><span>{{trofeus.trophy3}}</span></div>
                      </v-container>
                    </center>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <center>
                      <v-container class="d-flex align-center justify-center">
                        <div class="pr-3"><v-img :src="require('@/assets/star.png')" width="50px" heigth="50px"> </v-img></div>
                        <div><span>{{trofeus.trophy5}}</span></div>
                      </v-container>
                    </center>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <center>
                      <v-container class="d-flex align-center justify-center">
                        <div class="pr-3"><v-img :src="require('@/assets/relampago.png')" width="50px" heigth="50px"> </v-img></div>
                        <div><span>{{trofeus.trophy10}}</span></div>
                      </v-container>
                    </center>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <center>
                      <v-container class="d-flex align-center justify-center">
                        <div class="pr-3"><v-img :src="require('@/assets/apps.png')" width="40px" heigth="40px"> </v-img></div>
                        <div>
                          <span v-if="acertoApps">{{acertoApps}}%</span>
                          <span v-else>--%</span>
                        </div>
                      </v-container>
                    </center>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <center>
                      <v-container class="d-flex align-center justify-center">
                        <div class="pr-3"><v-icon size="50" color="blue">mdi-gamepad-variant</v-icon></div>
                        <div><span>{{frequenciaJogos}}</span></div>
                      </v-container>
                    </center>
                  </v-col>
                  <v-col cols="12">
                    <v-card-title class="justify-center green--text"> PTO ({{estatisticasTpcsRealizados.resolvidos}}/{{estatisticasTpcsRealizados.marcados}}): </v-card-title>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <center>
                      <v-container class="d-flex align-center justify-center">
                        <div class="pr-3">Q.Certas</div>
                        <div><span><b>{{estatisticasQuestoesTpcs.qCertas}}</b></span></div>
                      </v-container>
                    </center>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <center>
                      <v-container class="d-flex align-center justify-center">
                        <div class="pr-3">Q.Resolvidas</div>
                        <div><span><b>{{estatisticasQuestoesTpcs.qRespondidas}}</b></span></div>
                      </v-container>
                    </center>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <center>
                      <v-container class="d-flex align-center justify-center">
                        <div class="pr-3"><span>Acerto</span></div>
                        <div><span><b>{{estatisticasQuestoesTpcs.acerto}}</b></span></div>
                      </v-container>
                    </center>
                  </v-col>
                </v-row>
                </v-card>
              </div>          
              

                <v-col cols="12">
                </v-col>
                <v-col id="html-export-2" cols="8">
                  <ClassificacaoAluno v-if="desempenhoUltimo" :posicoes="desempenhoUltimo"/>
                </v-col>

                <!-- ALL APPS -->
                <v-col cols="12">
                  <center>
                    <v-btn v-if="!showApps" text @click="showApps=!showApps"><span><v-icon color="#FFD000">mdi-apps</v-icon>Apps</span><v-icon color="#009263"> mdi-arrow-down </v-icon> </v-btn>
                    <v-btn v-else text @click="showApps=!showApps"><v-icon color="#FFD000">mdi-apps</v-icon><span>Apps</span><v-icon color="#009263"> mdi-arrow-up </v-icon></v-btn>
                  </center> 
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                <v-slide-y-transition>
                    <v-card v-show="showApps" class="elevation-6 pa-3" style="border: 2px solid green !important;" color="grey lighten-3">
                      <v-text-field
                        v-model="filtrarApps"
                        label="Filtrar"
                        prepend-icon="mdi-magnify"
                        color="#009263"
                        single-line
                      ></v-text-field>
                      <v-data-table
                        class="elevation-3"
                        style="background-color:#EEEEEE"
                        :headers="headers_apps"
                        :items="apps"
                        :footer-props="footer_props"
                        :search="filtrarApps"
                      >
                        <template v-slot:item="row">
                          <tr :class="row.item.acerto>50 ? 'style-positivo' : 'style-negativo'" @click="showAppPorDia(row.item)">
                            <td>{{row.item.nome}}</td>
                            <td>{{row.item.ncertas}}</td>
                            <td>{{row.item.ntotal}}</td>
                            <td>{{row.item.acerto}}</td>
                            <td>{{row.item.frequencia}}</td>
                            <!-- <td>{{row.item.lastdate}}</td>
                            <td>{{row.item.horario}}</td> -->
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card>
                </v-slide-y-transition>
                </v-col>
                <!-- ALL JOGOS -->
                <v-col cols="12">
                  <center>
                    <v-btn v-if="!showJogos" text @click="showJogos=!showJogos"><v-icon color="#009263">mdi-gamepad-variant</v-icon><span>Jogos</span><v-icon color="#009263"> mdi-arrow-down </v-icon> </v-btn>
                    <v-btn v-else text @click="showJogos=!showJogos"><v-icon color="#009263">mdi-gamepad-variant</v-icon><span>Jogos</span><v-icon color="#009263"> mdi-arrow-up </v-icon></v-btn>
                  </center> 
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                  <v-slide-y-transition>
                      <v-card v-show="showJogos" class="elevation-6 pa-3" style="border: 2px solid green !important;" color="grey lighten-3">
                        <v-text-field
                          v-model="filtrarJogos"
                          label="Filtrar"
                          prepend-icon="mdi-magnify"
                          color="#009263"
                          single-line
                        ></v-text-field>
                        <v-data-table
                          class="elevation-3"
                          style="background-color:#EEEEEE"
                          :headers="headers_jogos"
                          :items="jogos"
                          :footer-props="footer_props"
                          :search="filtrarJogos"
                        >
                        <template v-slot:item="row">
                          <tr @click="showJogoPorDia(row.item)">
                              <td>{{row.item.nome}}</td>
                              <td>{{row.item.min}}</td>
                              <td>{{row.item.max}}</td>
                              <td>{{row.item.media}}</td>
                              <td>{{row.item.frequencia}}</td>
                              <!-- <td>{{row.item.lastdate}}</td>
                              <td>{{row.item.horario}}</td> -->
                            </tr>
                            
                          </template>
                        </v-data-table>
                      </v-card>
                  </v-slide-y-transition>
                </v-col>
                <!-- ALL TPCS   -->
                <v-col cols="12">
                  <center>
                    <v-btn v-if="!showTPCS" text @click="showTPCS=!showTPCS"><v-icon color="#009263">mdi-home-analytics</v-icon><span>PTO</span><v-icon color="#009263"> mdi-arrow-down </v-icon> </v-btn>
                    <v-btn v-else text @click="showTPCS=!showTPCS"><v-icon color="#009263">mdi-home-analytics</v-icon><span>PTO</span><v-icon color="#009263"> mdi-arrow-up </v-icon></v-btn>
                  </center> 
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                  <v-slide-y-transition>
                      <v-card v-show="showTPCS" class="elevation-6 pa-3" style="border: 2px solid green !important;" color="grey lighten-3">
                        <v-text-field
                          v-model="filtrarTPCS"
                          label="Filtrar"
                          prepend-icon="mdi-magnify"
                          color="#009263"
                          single-line
                        ></v-text-field>
                        <v-data-table
                          class="elevation-3"
                          style="background-color:#EEEEEE"
                          :headers="headers_tpcs"
                          :items="tpcs"
                          :footer-props="footer_props"
                          :search="filtrarTPCS"
                        >
                        <template v-slot:item="row">
                          <tr>
                              <td>{{row.item.tagname}}</td>
                              <td>{{row.item.ncertas}}</td>
                              <td>{{row.item.ntotal}}</td>
                              <td>{{row.item.acerto}}</td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>
                  </v-slide-y-transition>
                </v-col>
                <!-- ALL CAMPEONATOS -->
                <v-col cols="12">
                  <center>
                    <v-btn v-if="!showCampeonatos" text @click="showCampeonatos=!showCampeonatos"><v-icon color="#009263">mdi-trophy</v-icon><span>Campeonatos</span><v-icon color="#009263"> mdi-arrow-down </v-icon> </v-btn>
                    <v-btn v-else text @click="showCampeonatos=!showCampeonatos"><v-icon color="#009263">mdi-trophy</v-icon><span>Campeonatos</span><v-icon color="#009263"> mdi-arrow-up </v-icon></v-btn>
                  </center> 
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                  <v-slide-y-transition>
                      <v-card v-show="showCampeonatos" class="elevation-6 pa-3" style="border: 2px solid green !important;" color="grey lighten-3">
                        <v-text-field
                          v-model="filtrarCampeonatos"
                          label="Filtrar"
                          prepend-icon="mdi-magnify"
                          color="#009263"
                          single-line
                        ></v-text-field>
                        <v-data-table
                          class="elevation-3"
                          style="background-color:#EEEEEE"
                          :headers="headers_campeonatos"
                          :items="campeonatos"
                          :footer-props="footer_props"
                          :search="filtrarCampeonatos"
                        >
                        <template v-slot:item="row">
                          <tr>
                              <td>{{row.item.descricaoBackOffice}}</td>
                              <td>{{row.item.posTurma}}</td>
                              <td>{{row.item.posEscola}}</td>
                              <td>{{row.item.posHypatia}}</td>
                              <td>{{row.item.pontuacao}}</td>
                              <td>{{row.item.njogos}}</td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>
                  </v-slide-y-transition>
                </v-col>
         </v-row>
      </v-container>
      <v-dialog v-model="dialogDia">
        <AppDiaAluno v-if="dialogDia" :resultados="appPorDia" :app="appAtual" />
      </v-dialog>
      <v-dialog v-model="dialogJogoDia">
        <JogoDiaAluno v-if="dialogJogoDia && jogoAtual != 'Calculus'" :resultados="jogoPorDia" :jogo="jogoAtual"/>
        <CalculusDiaAluno v-else-if="dialogJogoDia" :resultados="jogoPorDia" :jogo="jogoAtual"/>
      </v-dialog>
      <v-dialog v-model="dialogAskForEmail" width="50%">
        <AskForEmail :subjectProp="emailSubject" @emailConfirmed="sendEmail"/>
      </v-dialog>
    </v-card>
</template>

<script>
import axios from "axios"
import Swal from 'sweetalert2'
import jsPDF from 'jspdf' 

const hypatiaImg = require("@/assets/hypatiamat.png")
const h = require("@/config/hosts").hostAPI
const hostApps =  require("@/config/hosts").hostApps
const hostJogos =  require("@/config/hosts").hostJogos
const hostTrofeus =  require("@/config/hosts").hostTrofeus
const hostCampeonatos = require("@/config/hosts").hostCampeonatos
const hostTpcs = require("@/config/hosts").hostAPITpcs
import AppDiaAluno from "@/components/Apps/AppDiaAluno.vue"
import JogoDiaAluno from "@/components/Jogos/JogoDiaAluno.vue"
import CalculusDiaAluno from "@/components/Jogos/CalculusDiaAluno.vue"
import ClassificacaoAluno from '@/components/Campeonatos/ClassificacaoAluno.vue'
import Cromos from '@/components/Alunos/CromosVisualizacao.vue'
import DropdownExport from '@/components/Common/DropdownExport';
import AskForEmail from '@/components/Common/AskForEmail';

  export default {
    components:{
      AppDiaAluno,
      JogoDiaAluno,
      CalculusDiaAluno,
      ClassificacaoAluno,
      Cromos,
      DropdownExport,
      AskForEmail
    },
    props:["idAluno", "turma", "dataInicioProp", "dataFimProp", "alunoProp", "anoLetivoProp"],
    data(){
      return {
        dialogDia: false,
        dialogJogoDia: false,
        dialogAskForEmail: false,
        aluno: {},
        showApps: false,
        showJogos: false,
        showTPCS: false,
        showCampeonatos: false,
        trofeus:{trophy3:0, trophy5:0, trophy10:0},
        filtrarApps:"",
        filtrarJogos:"",
        filtrarTPCS: "",
        filtrarCampeonatos: "",
        headers_apps: [
            {text: "App", sortable: true, value: 'nome', class: 'subtitle-1'},
            {text: "NTRC", value: 'ncertas', class: 'subtitle-1'},
            {text: "NTR", value: 'ntotal', class: 'subtitle-1'},
            {text: "Acerto (%)", value: 'acerto', class: 'subtitle-1'},
            {text: "#", value: 'frequencia', class: 'subtitle-1'},
            // {text: "Data", value: 'lastdate', class: 'subtitle-1'},
            // {text: "Hora", value: 'horario', class: 'subtitle-1'},
        ],
        headers_jogos: [
            {text: "Jogo", sortable: true, value: 'nome', class: 'subtitle-1'},
            {text: "Min", value: 'min', class: 'subtitle-1'},
            {text: "Max", value: 'max', class: 'subtitle-1'},
            {text: "Média", value: 'media', class: 'subtitle-1'},
            {text: "#", value: 'frequencia', class: 'subtitle-1'},
            // {text: "Data", value: 'lastdate', class: 'subtitle-1'},
            // {text: "Hora", value: 'horario', class: 'subtitle-1'},
        ],
        headers_tpcs: [
            {text: "PTO", sortable: true, value: 'tagname', class: 'subtitle-1'},
            {text: "NQRC", value: 'ncertas', class: 'subtitle-1'},
            {text: "NQR", value: 'ntotal', class: 'subtitle-1'},
            {text: "Acerto(%)", value: 'acerto', class: 'subtitle-1'},
        ],
        headers_campeonatos: [
            {text: "Campeonato", sortable: true, value: 'descricaoBackOffice', class: 'subtitle-1'},
            {text: "Posição (Turma)", value: 'posTurma', class: 'subtitle-1'},
            {text: "Posição (Agr. Escolas)", value: 'posEscola', class: 'subtitle-1'},
            {text: "Posição (Hypatia)", value: 'posHypatia', class: 'subtitle-1'},
            {text: "Pontuação", value: 'pontuacao', class: 'subtitle-1'},
            {text: "#Jogos", value: 'njogos', class: 'subtitle-1'}
        ],
        footer_props: {
            "items-per-page-text": "Mostrar",
            "items-per-page-options": [5, 10, 20, -1],
            "items-per-page-all-text": "Todos"
        },
        token: "",
        id : 0,
        type: 0,
        filtrar:"",
        appPorDia:[],
        appAtual:"",
        jogoPorDia:[],
        jogoAtual:"",
        showAjuda:false,
        frequenciaJogos:0,
        ultimoCampeonato:{},
        acertoApps:0,
        desempenhoUltimo:{posTurma: "-", posEscola: "-", posHypatia: "-", pontuacao: 0},
        estatisticasQuestoesTpcs: {qCertas: 0, qRespondidas: 0, acerto: '--%'},
        estatisticasTpcsRealizados: {marcados: 0, resolvidos: 0, acerto: '--%'},
        jogos: [],
        apps: [],
        tpcs: [],
        campeonatos: [],
        isLoading: false,
        utilizador: {},
        number0or1: v  => {
          if (!v.trim()) return true;
          if (!isNaN(parseInt(v)) && (v == 0 || v == 1)) return true;
          return 'Tem que ser 0 ou 1';
        },
        number: v  => {
          if (!v.trim()) return true;
          if (!isNaN(parseInt(v))) return true;
          return 'Tem que ser um inteiro';
        } 

      }
    },
    created: async function(){
        this.token = localStorage.getItem("token");
        this.utilizador = JSON.parse(localStorage.getItem("utilizador"));
        if(!this.idAluno){
          const aluno = JSON.parse(localStorage.getItem("utilizador"))
          const response = await axios.get(h + "alunos/" + aluno.id + "?token=" + this.token)
          this.aluno = response.data
          this.aluno.id = aluno.id
          this.aluno.nomeType = "Aluno"
        }
        else{
          this.aluno = this.alunoProp;
        }
        this.dataInicio = this.dataInicioProp;
        this.dataFim = this.dataFimProp;
        this.getMonitorizacaoPorAluno();
    },
    computed: {
      xl() {
        if (this.$vuetify.breakpoint.xl) return true
        return false
      },
      small(){
        if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) return true
        return false
      },
      emailSubject(){
        return `Monitorização do aluno ${this.aluno.nome}`;
      },
      pdfFileName(){
        const pdfName =  `MonitorizaçãoAluno-${this.aluno.user}.pdf`;
        return pdfName;
      }
    },
    methods: {
      getMonitorizacaoPorAluno: async function(){
        this.isLoading = true;
        const queryString = `?turma=${this.turma}&dataInicio=${this.dataInicio}&dataFim=${this.dataFim}&token=${this.token}`;
        const allResults = await Promise.all([
          axios.get(`${h}alunos/${this.aluno.user}/monitorizacaoGeral${queryString}`),
          this.getTrofeus(),
          this.getAcertoApps(),
          this.getFrequenciaJogos(),
          this.getTPCs(),
          this.calculaUltimoCampeonato()
        ]);
        const response = allResults[0];
        if(response && response.data){
          const data = response.data;
          this.apps = data.apps || [];
          this.jogos = data.jogos || [];
          this.tpcs = data.tpcs || [];
          this.campeonatos = data.campeonatos || [];
        }
        this.isLoading = false;
      },
      getFrequenciaJogos: async function(){
        try {
          const response = await axios.get(hostJogos + "alunos/" + this.aluno.user + "/frequencia?token=" + this.token)
          this.frequenciaJogos = response.data
        } 
        catch(error){

        }
      },
      getAcertoApps: async function(){
        try {
          const response = await axios.get(hostApps + "alunos/" + this.aluno.user + "/acerto?token=" + this.token)
          if(response.data) this.acertoApps = response.data.acerto;
        }
        catch(error){

        }   
      },
      getTrofeus: async function(){
        try{
          const response = await axios.get(hostTrofeus + "alunos/" + this.aluno.user + "/?token=" + this.token).catch(() => {});
          if(response.data) this.trofeus = response.data;
        }
        catch(error){

        }  
        return;
      },
      calculaUltimoCampeonato: async function(){
        try{
          var ultimo = await axios.get(hostCampeonatos + "alunos/" + this.aluno.user + "/ultimocampeonato?token=" + this.token)
          this.ultimoCampeonato = ultimo.data
          if(this.ultimoCampeonato){
              var response = await axios.get(hostCampeonatos + this.ultimoCampeonato.campeonatoID + "/alunos/" + this.aluno.user
                                          + "?jogo=" + this.ultimoCampeonato.jogo + "&codprofessor=" + this.ultimoCampeonato.codprofessor
                                          + "&turma=" + this.ultimoCampeonato.turma + "&escola=" + this.aluno.escola + "&token=" + this.token)
              if(response.data) {
                  this.desempenhoUltimo = response.data
              }
          } 
        }
        catch(error){

        }  
      },
      showAppPorDia : async function(app){
        var response = await axios.get(hostApps + "alunos/" + this.aluno.user + "/dias?codtema=" + app.grupo
                                        + "&codsubtema=" + app.appid + "&token=" + this.token)
        this.appPorDia = response.data
        this.appAtual = app.nome
        this.dialogDia = true
      },
      showJogoPorDia: async function(jogo){
        if(jogo.nome != 'CalcRapid'){
          if(jogo.nome == 'Calculus'){
            var response = await axios.get(hostJogos + "minutenew/alunos/" + this.aluno.user + "/dias?tipo="
                                          + jogo.tipo + "&token=" + this.token)
          }
          else{
            var response = await axios.get(hostJogos + jogo.jogotable + "/alunos/" + this.aluno.user + "/dias?tipo="
                                          + jogo.tipo + "&token=" + this.token)
          }
          this.jogoAtual = jogo.nome
          this.jogoPorDia = response.data
          this.dialogJogoDia = true
        }
      },
      verJogos: function(){
        this.$router.push({name: "Jogos Alunos"})
      },
      verApps: function(){
        this.$router.push({name: "Apps Alunos"})
      },
      getTPCs: async function(){
        try {
          const response = await axios.get(`${hostTpcs}alunos/${this.aluno.user}/estatisticas?token=${this.token}`);
          if(response && response.data) {
            if(response.data.questoesRealizadas){
              this.estatisticasQuestoesTpcs = response.data.questoesRealizadas;
            }
            if(response.data.tpcsRealizados){
              this.estatisticasTpcsRealizados = response.data.tpcsRealizados;
            }
          }
        }
        catch(error){

        }
      },
      getJSPDF: async function(){
        let doc = new jsPDF({});
        let xImage = doc.internal.pageSize.getWidth() / 4;
        let ytotal = 0;
        const startX = 15;
        const startY = 50;
        let iY = 0;
        doc.addImage(hypatiaImg, 'PNG', xImage, 4);
        //doc.text("Jogo:")
        //doc.text("Estatisticas dos alunos sobre o jogo " + this.jogo + "da turma " + this.turmaSel, doc.internal.pageSize.getWidth() / 2, 8, null, null, 'center')
        doc.setFontSize(11);
        doc.text("Professor: " + this.utilizador.nome, startX, startY + (iY++ * 6));
        doc.text(`Aluno: ${this.aluno.nome}, N.º ${this.aluno.numero}`, startX, startY + (iY++ * 6));
        doc.text(`Turma: ${this.turma}`, startX, startY + (iY++ * 6))
        // doc.text("Período: " + this.dataInicioProp + " até " + this.dataFimProp, 15, 56);
        doc.text("Ano letivo: " + this.anoLetivoProp, startX, startY + + (iY++ * 6));
        doc.text("Data: " + (new Date()).toISOString().split("T")[0], startX, startY + (iY * 6));
        let bodyStartY = startY + (iY * 6) + 8;
        const htmlExport1Height = await this.drawHTMLElementForPdfDoc(doc, "html-export-1", startX, bodyStartY);
        bodyStartY += htmlExport1Height ? htmlExport1Height + 8 : 0;
        const htmlExport2Height = await this.drawHTMLElementForPdfDoc(doc, "html-export-2", startX, bodyStartY);
        bodyStartY += htmlExport2Height ? htmlExport2Height + 8 : 0;
        if(this.apps && this.apps.length){
          bodyStartY = this.drawAppsForPdfDoc("Apps", doc, startX, bodyStartY);
        }
        if(this.jogos && this.jogos.length){
          bodyStartY = this.drawJogosForPdfDoc("Jogos", doc, startX, bodyStartY);
        }
        if(this.tpcs && this.tpcs.length){
          bodyStartY = this.drawTpcsForPdfDoc("PTO", doc, startX, bodyStartY);
        }
        if(this.campeonatos && this.campeonatos.length){
          bodyStartY = this.drawCampeonatosPdfDoc("Campeonatos", doc, startX, bodyStartY);
        }
        return doc;
      }, 
      exportPDF: async function(){
        const doc = await this.getJSPDF();
        doc.save(this.pdfFileName);
      },
      drawHTMLElementForPdfDoc: async function(doc, htmlElementID, x, y){
        let finalHeight = 0;
        const htmlElement = document.getElementById(htmlElementID);
        if(htmlElement){
          const options = {
            scale: 1,
            useCORS: true,
            allowTaint: true
          }
          const canvas = (await this.$html2canvas(htmlElement, options));
          const imgData = canvas.toDataURL("image/png");

          // Create a jsPDF document and calculate dimensions
          const pdfWidth = doc.internal.pageSize.getWidth() - 30;
          const pdfHeight = doc.internal.pageSize.getHeight();

          // Adjust the image dimensions to fit within the PDF
          const imgWidth = canvas.width / options.scale;
          const imgHeight = canvas.height / options.scale;

          // Scale down the image to fit the PDF dimensions
          const widthRatio = pdfWidth / imgWidth;
          const heightRatio = pdfHeight / imgHeight;
          const ratio = Math.min(widthRatio, heightRatio);

          const finalWidth = imgWidth * ratio;
          finalHeight = imgHeight * ratio;

          // Add the image to the PDF at the calculated size and save
          doc.addImage(imgData, 'PNG', x, y, finalWidth, finalHeight);
        }
        return finalHeight;
      },
      drawAppsForPdfDoc(title, doc, startX, startY){
        doc.setFont(doc.getFont().fontName, "bold");
        doc.setFontSize(13);
        doc.text(title, startX, startY);
        startY += 6;
        const listaRes = []
        let total =["Todas", 0, 0, 0, 0]
        for(let i = 0; i<this.apps.length; i++){
            const aux = [];
            aux.push(this.apps[i].nome);
            aux.push(this.apps[i].ncertas);
            total[1] += this.apps[i].ncertas;
            aux.push(this.apps[i].ntotal);
            total[2] += this.apps[i].ntotal;
            aux.push(this.apps[i].acerto);
            aux.push(this.apps[i].frequencia);
            total[4] += this.apps[i].frequencia;
            listaRes.push(aux);
        }
        total[3] = total[2] ? Math.round((total[1]/total[2]) * 100) :  '-';
        listaRes.push(total);
        const headers = ["App", 'NTRC', "NTR", "Acerto(%)", "#"];
        doc.autoTable({
            head: [headers],
            body: listaRes,
            headStyles: { fillColor: [0, 146, 99] },
            startY: startY,
            willDrawCell: function (data) {
                    var rows = data.table.body;
                    if (data.row.index === rows.length - 1) {
                        doc.setFillColor(5, 179, 123);
                        doc.setTextColor(255, 255, 255);
                    }
                },
        });

        // Calculate Y position for the footer after the tables
        let footerYPosition = doc.lastAutoTable.finalY + 40; // Adjust padding as needed
        const pageHeight = doc.internal.pageSize.getHeight();

        // Check if footer will fit on the current page; if not, add a new page
        if (footerYPosition > pageHeight) {
          doc.addPage();
          footerYPosition = 40; // Reset footer position at the top of the new page
        }

        // Add the footer once, after the tables
        doc.setFont(doc.getFont().fontName, "normal");
        doc.setFontSize(8);
        let ydecrement = 26;
        let indexYDecrement = 0;
        doc.text("Legenda:", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        doc.text("App - Aplicação de conteúdo", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        doc.text("NTRC - N.º de tarefas realizadas corretamente", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        doc.text("NTR- N.º de tarefas realizadas", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        doc.text("Acerto (%) - (NTRC/NTR)*100", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        doc.text("# - Frequência", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        if(footerYPosition + 60 >= pageHeight){
          doc.addPage();
          return 10;
        }
        else {
          return footerYPosition + 10;
        }
      },
      drawJogosForPdfDoc(title, doc, startX, startY){
        doc.setFont(doc.getFont().fontName, "bold");
        doc.setFontSize(13);
        doc.text(title, startX, startY);
        startY += 6;
        const listaRes = []
        let total =["Todos", 0, 0, 0, 0];
        for(let i = 0; i<this.jogos.length; i++){
            const isCalcrapidOrCalculus = this.jogos[i].nome == "CalcRapid" || this.jogos[i].nome == "Calculus";
            const aux = [];
            aux.push(this.jogos[i].nome);
            aux.push(this.jogos[i].min);
            aux.push(this.jogos[i].max);
            aux.push(this.jogos[i].media);
            aux.push(this.jogos[i].frequencia)
            if(!isCalcrapidOrCalculus){
              if(total[1] > this.jogos[i].min || i == 0) total[1] = this.jogos[i].min;
              if(total[2] < this.jogos[i].max || i == 0) total[2] = this.jogos[i].max;
              total[3] += this.jogos[i].media * this.jogos[i].frequencia;
              total[4] += this.jogos[i].frequencia;
            }
            listaRes.push(aux);
        }
        total[3] = total[4] ? (total[3]/total[4]).toFixed(0) : "-";
        listaRes.push(total);
        const headers = ["Jogo", 'Min', "Máx", "Média", "#"];
        doc.autoTable({
            head: [headers],
            body: listaRes,
            headStyles: { fillColor: [0, 146, 99] },
            startY: startY,
            willDrawCell: function (data) {
                    var rows = data.table.body;
                    if (data.row.index === rows.length - 1) {
                        doc.setFillColor(5, 179, 123);
                        doc.setTextColor(255, 255, 255);
                    }
                },
        });

        // Calculate Y position for the footer after the tables
        let footerYPosition = doc.lastAutoTable.finalY + 40; // Adjust padding as needed
        const pageHeight = doc.internal.pageSize.getHeight();

        // Check if footer will fit on the current page; if not, add a new page
        if (footerYPosition > pageHeight) {
          doc.addPage();
          footerYPosition = 40; // Reset footer position at the top of the new page
        }

        // Add the footer once, after the tables
        doc.setFont(doc.getFont().fontName, "normal");
        doc.setFontSize(8);
        let ydecrement = 26;
        let indexYDecrement = 0;
        doc.text("Legenda:", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        doc.text("Jogo - Jogo", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        doc.text("Min - Mínimo de pontuação obtida", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        doc.text("Max - Máximo de pontuação obtida", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        doc.text("Média - Média de pontuação obtida", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        doc.text("# - Frequência", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        if(footerYPosition + 60 >= pageHeight){
          doc.addPage();
          return 10;
        }
        else {
          return footerYPosition + 10;
        }
      },
      drawTpcsForPdfDoc(title, doc, startX, startY){
        doc.setFont(doc.getFont().fontName, "bold");
        doc.setFontSize(13);
        doc.text(title, startX, startY);
        startY += 6;
        let listaRes = [];
        let total =["Todos", 0, 0, 0];
        for(let i = 0; i<this.tpcs.length; i++){
            let aux = [];
            let indexToTotal = 1;
            aux.push(this.tpcs[i].tagname);
            aux.push(this.tpcs[i].ncertas);
            total[indexToTotal++] += this.tpcs[i].ncertas;
            aux.push(this.tpcs[i].ntotal)
            total[indexToTotal++] += this.tpcs[i].ntotal;
            aux.push(this.tpcs[i].acerto);
            listaRes.push(aux);
        }
        total[3] = total[2] != 0 ? Math.round((total[1]/total[2]) * 100) : '-';
        listaRes.push(total);
        const headers = ["PTO", 'NQRC', "NQR", "Acerto(%)"];
        doc.autoTable({
            head: [headers],
            body: listaRes,
            headStyles: { fillColor: [0, 146, 99] },
            startY: startY,
            willDrawCell: function (data) {
                    var rows = data.table.body;
                    if (data.row.index === rows.length - 1) {
                        doc.setFillColor(5, 179, 123);
                        doc.setTextColor(255, 255, 255);
                    }
                },
        });

        // Calculate Y position for the footer after the tables
        let footerYPosition = doc.lastAutoTable.finalY + 40; // Adjust padding as needed
        const pageHeight = doc.internal.pageSize.getHeight();

        // Check if footer will fit on the current page; if not, add a new page
        if (footerYPosition > pageHeight) {
          doc.addPage();
          footerYPosition = 40; // Reset footer position at the top of the new page
        }

        // Add the footer once, after the tables
        doc.setFont(doc.getFont().fontName, "normal");
        doc.setFontSize(8);
        let ydecrement = 26;
        let indexYDecrement = 0;
        doc.text("Legenda:", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        doc.text("PTO - Proposta de trabalho online (nome)", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        doc.text("NQRC - N.º de questões resolvidas corretamente", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        doc.text("NQR - N.º de questões resolvidas", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        doc.text("Acerto (%) - (NQRC/NQR)*100", 10, footerYPosition - (ydecrement - (4 * indexYDecrement)));
        if(footerYPosition + 60 >= pageHeight){
          doc.addPage();
          return 10;
        }
        else {
          return footerYPosition + 10;
        }
      },
      drawCampeonatosPdfDoc(title, doc, startX, startY){
        doc.setFont(doc.getFont().fontName, "bold");
        doc.setFontSize(13);
        doc.text(title, startX, startY);
        startY += 6;
        let listaRes = [];
        for(let i = 0; i<this.campeonatos.length; i++){
            let aux = [];
            aux.push(this.campeonatos[i].descricaoBackOffice);
            aux.push(this.campeonatos[i].posTurma);
            aux.push(this.campeonatos[i].posEscola);
            aux.push(this.campeonatos[i].posHypatia);
            aux.push(this.campeonatos[i].pontuacao);
            aux.push(this.campeonatos[i].njogos);
            listaRes.push(aux);
        }
        const headers = ["Campeonato", "Posição (Turma)", "Posição (Escola)", "Posição (Hypatia)", "Pontuação", "#Jogos"];
        doc.autoTable({
            head: [headers],
            body: listaRes,
            headStyles: { fillColor: [0, 146, 99] },
            startY: startY
        });

        // Calculate Y position for the footer after the tables
        let footerYPosition = doc.lastAutoTable.finalY + 10; // Adjust padding as needed
        return footerYPosition;
        // const pageHeight = doc.internal.pageSize.getHeight();

        // // Check if footer will fit on the current page; if not, add a new page
        // if (footerYPosition > pageHeight) {
        //   doc.addPage();
        //   footerYPosition = 40; // Reset footer position at the top of the new page
        // }

        // // Add the footer once, after the tables
        // doc.setFont(doc.getFont().fontName, "normal");
        // doc.setFontSize(8);
        // let ydecrement = 26;
        // let indexYDecrement = 0;
        // doc.text("Legenda:", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        // doc.text("#Jogos - Número de vezes que o jogo foi jogado pelo professor", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        // doc.text("NQRC - N.º de questões resolvidas corretamente", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        // doc.text("NQR - N.º de questões resolvidas", 10, footerYPosition - (ydecrement - (4 * indexYDecrement++)));
        // doc.text("Acerto (%) - (NQRC/NQR)*100", 10, footerYPosition - (ydecrement - (4 * indexYDecrement)));
        // if(footerYPosition + 60 >= pageHeight){
        //   doc.addPage();
        //   return 10;
        // }
        // else {
        //   return footerYPosition + 10;
        // }
      },
      exportEmailClicked(){
        console.log("email clicked");
        this.dialogAskForEmail = true;
      },
      sendEmail: async function(value){
        this.dialogAskForEmail = false;
        const doc = await this.getJSPDF();
        this.isLoading = true;
        const docOutput = doc.output();
        const base64pdf = btoa(docOutput);
        try {
          const body = {
            files: [
              {
                filename: this.pdfFileName,
                content: base64pdf,
                encoding: 'base64'
              }
            ],
            from: this.utilizador.nome,
            ...value
          };
          await axios.post(`${h}emails/enviarBase64?token=${this.token}`, body);
        }
        catch(error) {
          console.log("falha ao enviar email");
          console.error(error);
        }
        finally {
          this.isLoading = false;
        }
      }

    }
  }
</script>

<style>
.style-negativo {
  background-color: #f26755
}
.style-positivo {
  background-color: #10de16
}
</style>